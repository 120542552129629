let tCPromise: Promise<TagCommander.WebAPI> = null;

export const getTC = (): Promise<TagCommander.WebAPI> => {
  // loaded
  if (tCPromise) {
    return tCPromise;
  }

  if (window.tC) {
    tCPromise = Promise.resolve(window.tC);

    return tCPromise;
  }

  // wait for tC async callback to be called
  tCPromise = new Promise((resolve) => (window.onTCLoad = () => resolve(window.tC)));

  return tCPromise;
};
