import Block from "./block";
import { getOffers } from "../lib/offer";
import { trialDurationLabelTemplate } from "../templates/trial_duration_label";

export default class TrialDurationLabelBlock extends Block {
  constructor(el: HTMLElement) {
    super(el);
    this.render();
  }

  async render(): Promise<void> {
    const offers = await getOffers();
    this.el.appendChild(trialDurationLabelTemplate({ offers }));
  }
}
