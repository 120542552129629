type Topic = string;
type PubSubEvents = Record<Topic, unknown[]>;
type EventCallback<Args extends unknown[]> = (...args: Args) => void;
type Mapping<Events extends PubSubEvents> = Record<
  keyof Events,
  EventCallback<Events[keyof Events]>[]
>;

export default class PubSub<Events extends PubSubEvents> {
  mapping: Mapping<Events>;

  constructor() {
    this.mapping = <Mapping<Events>>{};
  }

  publish<T extends keyof Events>(topic: T, ...args: Events[T]): unknown[] {
    return (this.mapping[topic] || []).map((fn) => fn(...args));
  }

  // returns a function to call if you want to unsubscribe
  subscribe<T extends keyof Events>(topic: T, callback: EventCallback<Events[T]>): () => void {
    const stack = this.mapping[topic] || [];
    this.mapping[topic] = stack;
    stack.push(callback);
    return this.unsubscribe.bind(this, topic, callback);
  }

  unsubscribe<T extends keyof Events>(topic: T, callback: EventCallback<Events[T]>): void {
    const stack = this.mapping[topic];
    const index = stack.indexOf(callback);
    if (index !== -1) {
      stack.splice(index, 1);
    }
  }
}
