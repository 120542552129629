import { apiHost, assetHost, currentProfileId as serverCurrentProfileId } from "../../page-data";
import { publish } from "../../actions";
import { getCookie, removeCookie, setCookie } from "../cookies";
import { authUserFetch } from "./index";
import { isParentStrict } from "./preferences";

const selectedProfileIdCookieName = "tfmspid";

let profiles: User.Profiles = null;
let currentProfileId: User.ProfileId = serverCurrentProfileId; // server session profile id
let selectedProfileId: User.ProfileId = null; // browser saved profiled id (user selection)

export const addProfileId = "add";

const setProfiles = (newProfiles: User.Profiles): void => {
  profiles = newProfiles;
};

export const getProfiles = async (forceUpdate = false): Promise<User.Profiles> => {
  // fetch profiles only once
  if (!forceUpdate && Array.isArray(profiles)) {
    return profiles;
  }

  const url = `${apiHost}/profiles`;
  const array = await authUserFetch<User.Profiles>("GET", url, "", { withCredentials: true });

  setProfiles(array);
  return profiles;
};

export const getProfileById = async (profileId: User.ProfileId): Promise<User.Profile> => {
  const profiles = await getProfiles();

  return profiles.find(({ id }) => id === profileId);
};

export const getProfileAvatarUrl = (avatar: User.Profile["avatar"]): string =>
  avatar || `${assetHost}/assets/images/avatar_default.png`;

export const updateProfile = async (profile: User.Profile): Promise<User.Profile> => {
  const { id: profileId } = profile;
  const url = `${apiHost}/profiles/${profileId}`;

  const updatedProfile = await authUserFetch<User.Profile>(
    "PUT",
    url,
    JSON.stringify({ ...profile }),
    {
      withCredentials: true,
      headers: { "Content-Type": "application/json" }
    }
  );

  // update profile in profiles array
  if (profiles) {
    profiles = profiles.map((profile) => (profile.id === profileId ? updatedProfile : profile));
  }

  publish("profiles:update");

  return updatedProfile;
};

export const deleteProfile = async (profileId: User.ProfileId): Promise<void> => {
  const url = `${apiHost}/profiles/${profileId}`;

  await authUserFetch<void>("DELETE", url, null, { withCredentials: true });

  // remove profile in profiles array
  if (profiles) {
    profiles = profiles.filter((profile) => profile.id !== profileId);
  }

  // update selected if deleted
  if (profileId === selectedProfileId) {
    removeSelectedProfile();
  }

  // update current if deleted
  if (profileId === currentProfileId) {
    await getCurrentProfileId(true);
  }

  publish("profiles:update");
};

export const createProfile = async (
  profile: Pick<User.Profile, "name" | "avatar">
): Promise<User.Profile> => {
  const url = `${apiHost}/profiles`;

  const createdProfile = await authUserFetch<User.Profile>("POST", url, JSON.stringify(profile), {
    withCredentials: true,
    headers: { "Content-Type": "application/json" }
  });

  // insert created profile in profiles array just before the first permanent one
  if (profiles) {
    const firstPermanentProfileIndex = profiles.findIndex(({ permanent }) => !!permanent);
    profiles.splice(firstPermanentProfileIndex, 0, createdProfile);
  }

  publish("profiles:update");

  return createdProfile;
};

const setCurrentProfileId = (newProfileId: User.ProfileId): void => {
  currentProfileId = newProfileId;
};

const updateCurrentProfileId = async (newProfileId: User.ProfileId): Promise<void> => {
  const url = `${apiHost}/profiles/current`;
  await authUserFetch<User.Profiles>("PUT", url, { id: newProfileId }, { withCredentials: true });

  setCurrentProfileId(newProfileId);

  publish("profiles:update");
};

export const getCurrentProfileId = async (forceUpdate = false): Promise<User.ProfileId> => {
  // fetch currentProfileId only once
  if (!forceUpdate) {
    return currentProfileId;
  }

  const url = `${apiHost}/profiles/current`;
  const { id } = await authUserFetch<User.Profile>("GET", url, "", { withCredentials: true });

  setCurrentProfileId(id);
  return currentProfileId;
};

export const getCurrentProfile = async (forceUpdate = false): Promise<User.Profile> => {
  const currentProfileId = await getCurrentProfileId(forceUpdate);

  return getProfileById(currentProfileId);
};

export const setSelectedProfileId = async (
  newProfileId: User.ProfileId,
  checkParent = false
): Promise<void> => {
  if (checkParent) {
    await isParentStrict();
  }

  await updateCurrentProfileId(newProfileId);
  setCookie(selectedProfileIdCookieName, newProfileId);
  selectedProfileId = newProfileId;
  publish("profiles:update");
};

export const removeSelectedProfile = (): void => {
  removeCookie(selectedProfileIdCookieName);
  selectedProfileId = null;
};

export const getSelectedProfileId = (): User.ProfileId => {
  // load from cache if set previously
  if (!selectedProfileId) {
    selectedProfileId = getCookie(selectedProfileIdCookieName) || null;
  }

  return selectedProfileId;
};
