import { subscribe } from "../actions";
import { getAuthAccount } from "./auth";

const gigyaScreenContainerBodyAttribute = "data-gigya-screen-container";

const preventGigyaPopupSkip = (): void => {
  document.addEventListener(
    "keyup",
    (event) => {
      // user tries to escape a gigya popup => stop event
      if (
        event.key.toLowerCase() === "escape" &&
        document.body.getAttribute(gigyaScreenContainerBodyAttribute) === "popup"
      ) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    true
  );
};

// auto-prompt some gigya screens if needed
const checkGigyaAutoPrompt = async (): Promise<void> => {
  const account = await getAuthAccount();

  if (!account) {
    return;
  }

  const legalAge = 16;
  const hasLegalAge = account.profile.age >= legalAge;
  if (!hasLegalAge) {
    const { requireLegalAge } = await import("../lib/screenset");
    await requireLegalAge();

    return checkGigyaAutoPrompt();
  }

  if (!account?.data?.tfoumax?.createdAt) {
    const { compteUniqueWelcome } = await import("../lib/screenset");
    await compteUniqueWelcome();

    return checkGigyaAutoPrompt();
  }
};

export const initAutoScreensets = (): void => {
  preventGigyaPopupSkip();
  subscribe("login:success", () => checkGigyaAutoPrompt());
  checkGigyaAutoPrompt();
};
