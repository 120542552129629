import { htmlToElement } from "../templates/helpers";
import { createElement } from "../lib/dom";

interface CarouselArrowsTemplateData {
  direction: "left" | "right";
}

const getButtonAriaLabel = (direction: CarouselArrowsTemplateData["direction"]): string =>
  `Faire défiler les programmes ${direction === "left" ? "précédents" : "suivants"}`;

export const carouselArrowsTemplate = ({ direction }: CarouselArrowsTemplateData): HTMLElement =>
  createElement(
    "button",
    `carousel_arrow unstyled_button ${direction}`,
    {
      type: "button",
      raw: { ["aria-label"]: getButtonAriaLabel(direction) }
    },
    [
      htmlToElement(
        '<svg class="svg_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" aria-hidden="true"><path d="M837.404 500.622c0 161.564-354.987 395.947-452.836 405.049s-177.493-120.604-175.218-186.596c0-65.991 259.413-227.556 259.413-227.556s-282.169-147.911-275.342-202.524c6.827-54.613-2.276-150.187 131.982-177.493 129.707-29.582 512 227.556 512 389.12z"/></svg>'
      )
    ]
  );
