import Block from "./block";
import { subscribe } from "../lib/user/subscription";
import { homeBottomSubscribeLinkClickHit } from "../lib/tms/catalog";

export default class SubscriptionCtaBlock extends Block {
  constructor(el: HTMLElement) {
    super(el);
    this.init();
  }

  init(): void {
    this.dom.delegate(this.el, "click", ".subscribe_button", () => {
      homeBottomSubscribeLinkClickHit();
      subscribe();
    });
  }
}
