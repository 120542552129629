import PubSub from "./lib/pubsub";

interface LoadMediaEventOptions {
  playAlong?: false | "auto" | "manuel";
}

type ActionsEvents = {
  "age-filters:update": [ages: Api.Age[]];
  "time-limit-settings:update": [timeLimit: number];
  "video-player:load-media": [videoId: string];
  "video-player:video-ended": [videoId: string];
  "video-player:show-next": [];
  "video-player:unshow-next": [];
  "video-playlist:load-media": [videoId: string, options?: LoadMediaEventOptions];
  "video-teasers:load-media": [videoId: string, options?: LoadMediaEventOptions];
  "logout:success": [];
  "login:success": [];
  "login:success:payment-new-user": [];
  "player-timer:update": [value: number, initValue: number];
  "consent:update": [response: TrustCommander.ConsentUpdatedResponseObject];
  "privacy-center:show": [];
  "favorites:add": [programId: string];
  "favorites:remove": [programId: string];
  "profiles:update": [];
  "timer:update": [newValue: number, profileId: string];
  "screenset:start": [];
  "screenset:end": [];
  "screenset:error": [error: Gigya.OnErrorEvent<unknown>];
  "screenset:after-screen-load": [event: Gigya.OnAfterScreenLoadEvent<unknown>];
  "screenset:before-submit": [event: Gigya.OnBeforeSubmitEvent<unknown>];
  "screenset:after-submit": [event: Gigya.OnAfterSubmitEvent<unknown>];
  "screenset:hide": [event: Gigya.OnHideEvent<unknown>];
};

type ActionsPubSub = PubSub<ActionsEvents>;

// global action events channel
// allow communications between separate components/libs
const pubsub = new PubSub<ActionsEvents>();

export const subscribe: ActionsPubSub["subscribe"] = pubsub.subscribe.bind(pubsub);
export const unsubscribe: ActionsPubSub["unsubscribe"] = pubsub.unsubscribe.bind(pubsub);
export const publish: ActionsPubSub["publish"] = pubsub.publish.bind(pubsub);
