import Block from "./block";
import * as dom from "../lib/dom";
import {
  isEnabled as isEnabledTrustedMode,
  toggle as toggleTrustedMode
} from "../lib/trusted-mode";
import { showPrivacyCenter } from "../lib/trust-commander";
import { footerLinkClickHit } from "../lib/tms/layout";

const linkNameAttribute = "data-footer-link-name";

export default class FooterBlock extends Block {
  trustedModeButton: HTMLButtonElement;
  privacyCenterButton: HTMLButtonElement;

  constructor(el: HTMLElement) {
    super(el);
    this.trustedModeButton = this.el.querySelector(".trusted_mode_btn");
    this.privacyCenterButton = this.el.querySelector(".privacy_center_btn");
    this.init();
    return this;
  }

  init(): void {
    if (this.trustedModeButton) {
      // trusted mode button
      this.autosetTrustedMode();
      dom.on(this.trustedModeButton, "click", this.toggleTrustedMode.bind(this));
    }
    if (this.privacyCenterButton) {
      dom.on(this.privacyCenterButton, "click", this.openPrivacyCenter.bind(this));
    }

    this.dom.delegate(this.el, "click", `[${linkNameAttribute}]`, this.onClickLink.bind(this));
  }

  autosetTrustedMode(): void {
    if (isEnabledTrustedMode()) {
      dom.addClass(this.trustedModeButton, "on");
    } else {
      dom.removeClass(this.trustedModeButton, "on");
    }
  }

  toggleTrustedMode(event: Event): void {
    event.preventDefault();
    toggleTrustedMode();
    this.autosetTrustedMode();

    if (isEnabledTrustedMode()) {
      window.location.href = "/";
    } else {
      window.location.reload();
    }
  }

  openPrivacyCenter(event: Event): void {
    event.preventDefault();
    showPrivacyCenter();
  }

  onClickLink({ delegateMatch: link }: Event): void {
    footerLinkClickHit(link.getAttribute(linkNameAttribute));
  }
}
