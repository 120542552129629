import { authHost } from "../page-data";
import { get } from "./fetch";
import { getOrigin } from "./origin";

let refreshPromise = null;

const finishAuth = (
  uid: Gigya.UID,
  uidSignature: Gigya.UIDSignature,
  signatureTimestamp: Gigya.SignatureTimestamp,
  isNewUser: boolean,
  keepMeLoggedIn: boolean
): Promise<void> => {
  const url = `${authHost}/gigya`;
  const origin = getOrigin();

  return get(url, {
    data: {
      UID: uid,
      UIDSignature: uidSignature,
      signatureTimestamp,
      keepMeLoggedIn,
      ...(isNewUser && origin ? { origin } : {})
    },
    withCredentials: true
  });
};

interface RefreshParameters {
  UID: Gigya.UID;
  UIDSignature: Gigya.UIDSignature;
  signatureTimestamp: Gigya.SignatureTimestamp;
}

export const refresh = (
  { UID: uid, UIDSignature: uidSignature, signatureTimestamp }: RefreshParameters,
  isNewUser = false,
  keepMeLoggedIn = false
): Promise<void> => {
  // refreshing
  if (refreshPromise) {
    return refreshPromise;
  }

  refreshPromise = finishAuth(uid, uidSignature, signatureTimestamp, isNewUser, keepMeLoggedIn)
    .catch((error) => {
      // ignore for now because oauth finish url sends 400 error
      // TODO fix this error
      if (window.console) {
        console.error(error);
      }
    })
    .then(() => {
      refreshPromise = null; // reset promise
    });

  return refreshPromise;
};
