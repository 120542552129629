// optional ttl param must be in seconds
// path is '/' by default
export const setCookie = (
  name: string,
  value: string = null,
  ttl: number = null,
  path: string = null,
  domain: string = null
): void => {
  const props = [`${name}=${window.encodeURIComponent(value)}`];
  if (ttl) {
    props.push(`expires=${new Date(Date.now() + ttl * 1000).toUTCString()}`);
  }
  props.push(`path=${path || "/"}`);
  if (domain) {
    props.push(`domain=${domain}`);
  }
  document.cookie = props.join("; ");
};

export const getCookie = (name: string): string => {
  const nameEq = `${name}=`;
  const pairs = document.cookie.split(";");
  let value = null;
  pairs.find((pair) => {
    pair = pair.trim();
    if (pair.indexOf(nameEq) === 0) {
      value = window.decodeURIComponent(pair.substr(nameEq.length));
      return true;
    }
    return false;
  });
  return value;
};

export const removeCookie = (name: string, path: string = null, domain: string = null): void =>
  setCookie(name, "", -1, path, domain);

export const cookiesRefs = {
  promoCode: "_TFOUMAX_PROMO_CODE_",
  origin: "_TFOUMAX_ORIGIN_",
  trustedMode: "_TFOUMAX_TRUSTED_MODE_",
  pairingCode: "_TFOUMAX_PAIRING_CODE_"
};
