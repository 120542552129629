import { isActive as isActiveValue } from "../../page-data";
import { getUserData, isAuthenticated, isEligibleForOfferId } from "./index";
import { canSubscribeOnWeb } from "./authorizations";
import { register } from "../auth";
import { getOffers, getOfferPaymentUrl } from "../offer";
import { getPaymentUrl } from "../payment";
import type OfferChoiceModal from "../../modals/offerChoiceModal";
import type UnavailableSubscriptionModal from "../../modals/unavailableSubscriptionModal";
import { getCookie, removeCookie, setCookie } from "../cookies";

const subscriptionReactivationUrl = "/user/subscription/reactivate";

export const getSubscription = async (): Promise<User.SubscriptionData> => {
  const { subscription } = await getUserData();
  return subscription;
};

const isSubscriptionStatus = async (status: User.SubscriptionData["status"]): Promise<boolean> => {
  const { status: subscriptionStatus } = await getSubscription();
  return subscriptionStatus === status;
};

export const isValidSubscription = (): Promise<boolean> => isSubscriptionStatus("valid");

export const reactivateSubscription = (successUrl: string): void => {
  window.location.href = `${subscriptionReactivationUrl}?successUrl=${window.encodeURIComponent(
    successUrl
  )}`;
};

interface SubscribeParameters {
  newUser?: boolean;
  offerId?: string;
  onModalClose?(): void | null;
}

const subscriptionOfferIdCookieName = "tfoumax_sub_offer_id";

const getSubscriptionOfferId = (): string | null => getCookie(subscriptionOfferIdCookieName);

const saveSubscriptionOfferId = (offerId: string): void =>
  setCookie(subscriptionOfferIdCookieName, offerId);

const removeSubscriptionOfferId = (): void => removeCookie(subscriptionOfferIdCookieName);

const loadSubscriptionOfferId = () => {
  const offerId = getSubscriptionOfferId();

  removeSubscriptionOfferId();
  return offerId;
};

const getSubscriptionModalCloseCallback = (onModalClose: SubscribeParameters["onModalClose"]) => {
  return onModalClose
    ? (modal: OfferChoiceModal | UnavailableSubscriptionModal): void => {
        modal.close();
        onModalClose();
      }
    : null;
};

const chooseOffer = async (
  isActive: boolean,
  onModalClose: SubscribeParameters["onModalClose"],
  hasEligibilityFailed = false
): Promise<void> => {
  const offers = await getOffers();

  if (!offers.length) {
    throw new Error("No offers available");
  }

  if (offers.length === 1) {
    window.location.href = getOfferPaymentUrl(offers[0]);
    return;
  }

  const { default: OfferChoiceModal } = await import("../../modals/offerChoiceModal");
  const closeCallback = getSubscriptionModalCloseCallback(onModalClose);
  const onOfferClick = (offerId: string) => subscribe({ offerId });

  new OfferChoiceModal(isActive, offers, hasEligibilityFailed, onOfferClick, closeCallback).open();
};

export const subscribe = async ({
  newUser = false,
  offerId = null,
  onModalClose = null
}: SubscribeParameters = {}): Promise<void> => {
  if (!canSubscribeOnWeb()) {
    const { default: UnavailableSubscriptionModal } = await import(
      "../../modals/unavailableSubscriptionModal"
    );
    const closeCallback = getSubscriptionModalCloseCallback(onModalClose);
    return new UnavailableSubscriptionModal(closeCallback).open();
  }

  const isAuthenticatedValue = await (newUser ? true : isAuthenticated());
  const isActive = newUser ? false : isActiveValue;

  if (!isAuthenticatedValue) {
    if (offerId) {
      saveSubscriptionOfferId(offerId);
      return register();
    }

    return chooseOffer(isActive, onModalClose);
  }

  if (isActive) {
    throw new Error("User is already subscribed");
  }

  // load and remove previously saved offerId
  const savedOfferId = loadSubscriptionOfferId();
  offerId ||= savedOfferId;

  const isEligible = offerId && (await isEligibleForOfferId(offerId));

  if (isEligible) {
    window.location.href = getPaymentUrl(offerId);
    return;
  }

  chooseOffer(isActive, onModalClose, offerId && !isEligible);
};

// Possibilities :
// - new user with saved offer id
//   * isEligible => return payment url
//   * !isEligible => choose offer with hasEligibilityFailed as true
// - new user with no saved offer id => choose offer
// - !new user && !isActive with saved offer id
//   * isEligible => return payment url
//   * !isEligible => choose offer with hasEligibilityFailed as true
// - !new user && !isActive without saved offer id => default url
// - !new user isActive => default url
export const getPostLoginUrl = async (defaultUrl: string, newUser: boolean): Promise<string> => {
  // get fresh isActive value
  const {
    subscription: { isActive }
  } = await getUserData();
  const offerId = loadSubscriptionOfferId();

  if (isActive) {
    return defaultUrl;
  }

  // no saved offer
  if (!offerId) {
    // existing user
    if (!newUser) {
      return defaultUrl;
    }

    // new user
    subscribe({
      newUser,
      onModalClose: () => {
        window.location.href = defaultUrl;
      }
    });

    return "none";
  }

  const isEligible = await isEligibleForOfferId(offerId);

  if (isEligible) {
    return getPaymentUrl(offerId);
  }

  subscribe({
    newUser,
    offerId,
    onModalClose: () => {
      window.location.href = defaultUrl;
    }
  });

  return "none";
};
