import Block from "./block";
import { getOffers } from "../lib/offer";
import { offersLabelTemplate } from "../templates/offers_label";

export default class OffersLabelBlock extends Block {
  constructor(el: HTMLElement) {
    super(el);
    this.render();
  }

  async render(): Promise<void> {
    const format = this.el.dataset.format;
    const offers = await getOffers();
    this.el.appendChild(offersLabelTemplate({ offers, format }));
  }
}
