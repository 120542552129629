import { apiHost } from "../page-data";
import { get, parseJSON } from "./fetch";
import { getPaymentUrl } from "./payment";

const trialDisplay = "essai";
const monthlyPeriodicity = "monthly";
const yearlyPeriodicity = "yearly";

export const isTrialOffersItem = ({ starter: { display } }: Api.OffersItem): boolean =>
  display === trialDisplay;

export const isRenewableOffersItem = ({ renewal }: Api.OffersItem): boolean => !!renewal;

export const hasTrialOffer = (offers: Api.Offers): boolean => offers.every(isTrialOffersItem);

const isMonthlyOffersItem = ({ renewal: { periodicity } }: Api.OffersItem): boolean =>
  periodicity === monthlyPeriodicity;

export const findMonthlyOffersItem = (offers: Api.Offers): Api.OffersItem =>
  offers.find(isMonthlyOffersItem);

const isAnnualOffersItem = ({ renewal: { periodicity } }: Api.OffersItem): boolean =>
  periodicity === yearlyPeriodicity;

export const findAnnualOffersItem = (offers: Api.Offers): Api.OffersItem =>
  offers.find(isAnnualOffersItem);

export const getReferenceOffersItem = (offers: Api.Offers): Api.OffersItem =>
  findMonthlyOffersItem(offers) || offers[0];

export const getOfferPaymentUrl = ({ starter: { id: offerId } }: Api.OffersItem): string =>
  getPaymentUrl(offerId);

// return renewal if any, starter otherwise
export const getFinalOffer = ({ starter, renewal }: Api.OffersItem): Api.Offer =>
  renewal || starter;

// sort offers by price (return a sorted shallow copy array)
export const sortOffersByPrice = (offers: Api.Offers, ascending = true): Api.Offers =>
  [...offers].sort(
    (offersItemA, offersItemB) =>
      (getFinalOffer(offersItemA).price - getFinalOffer(offersItemB).price) * (ascending ? 1 : -1)
  );

const offersCacheKey = "tfoumax_web_offers";
let offers: Api.Offers = null;

export const getOffers = async (): Promise<Api.Offers> => {
  if (offers) {
    return offers;
  }

  // read value from session storage
  const cacheValue = window.sessionStorage.getItem(offersCacheKey);

  if (cacheValue) {
    offers = JSON.parse(cacheValue);
    return offers;
  }

  const url = `${apiHost}/web/offers`;
  offers = sortOffersByPrice(
    await get<Api.Offers>(url, {
      requestId: url,
      parse: parseJSON,
      withCredentials: true
    })
  );

  // save value in session storage
  window.sessionStorage.setItem(offersCacheKey, JSON.stringify(offers));

  return offers;
};

export const clearOffersCache = (): void => window.sessionStorage.removeItem(offersCacheKey);
