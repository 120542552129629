import { apiHost } from "../page-data";
import { getCookie, setCookie, cookiesRefs, removeCookie } from "../lib/cookies";
import { parseJSON, get, post } from "./fetch";

export const save = (code: string): void => setCookie(cookiesRefs.promoCode, code);

export const load = (): string => {
  const code = getCookie(cookiesRefs.promoCode);
  removeCookie(cookiesRefs.promoCode);
  return code;
};

export const validate = (code: string): Promise<void> => {
  const url = `${apiHost}/soft_checkpoint`;
  const data = { code };
  return get(url, { data });
};

interface PromoCodeUseResponse {
  redirectUrl: string;
}

export const use = async (code: string): Promise<string> => {
  if (!code) {
    throw new Error("Promo code is not defined");
  }

  const url = "/user/subscription/promo-code";
  const requestId = `${url}:${code}`;

  const { redirectUrl } = await post<PromoCodeUseResponse>(url, {
    requestId,
    data: { code },
    withCredentials: true,
    parse: parseJSON
  });

  if (!redirectUrl) {
    throw new Error("Redirect url is missing");
  }

  return redirectUrl;
};
