import { frontHost } from "../page-data";
import { get, AjaxOptions } from "./fetch";

const cache: Map<string, unknown> = new Map();
const partialBaseUrl = `${frontHost}/partial`;

const apiFetch = async <T>(
  path: string,
  data: AjaxOptions<T>["data"] = null,
  cached = false
): Promise<T> => {
  const requestId = `${path}:${JSON.stringify(data)}`;
  // already in cache
  if (cached && cache.has(requestId)) {
    return <T>cache.get(requestId);
  }

  const response = await get<T>(partialBaseUrl + path, {
    requestId,
    data
  });

  if (cached) {
    cache.set(requestId, response); // cache response
  }

  return response;
};

export const getProgramOverview = (programId: string): Promise<string> => {
  if (!programId) {
    throw new Error("Program id is not defined");
  }

  return apiFetch<string>(`/program_overview/${programId}`, null, true);
};

export const getProgramSearchResult = (input: string): Promise<string> =>
  apiFetch<string>(`/program_search_result`, { input }, true);

export const getAccountDropdownProfiles = (): Promise<string> =>
  apiFetch<string>("/account_dropdown_profiles");

interface ProfileAvatarPartialOptions {
  className?: string;
  width?: number;
  height?: number;
}

export const getProfileAvatar = (
  id: string,
  { className = "", width = 46, height = 46 }: ProfileAvatarPartialOptions = {}
): Promise<string> => apiFetch<string>("/profile_avatar", { id, className, width, height });

export const getProfilesManagementProfileList = (): Promise<string> =>
  apiFetch<string>("/profiles_management_profile_list");
