import { screenType, statisticsId } from "../../page-data";
import {
  pageEvent,
  clickEvent,
  displayPageId,
  ctaLinkId,
  hit,
  defaultDisplayPageHit,
  getScreenParams,
  getConsentParams
} from "./index";
import { subscribe } from "../../actions";
import { delegate } from "../dom";

const privacyPopinScreenType = "popin-privacy";
const privacyCenterScreenType = "privacy-center";
const trackingOptoutScreenType = "maec_opposition";

const consentHit = (
  eventName: DataLayer.EventName,
  id: string,
  ...paramsArgs: (DataLayer.Params.Default | Promise<DataLayer.Params.Default>)[]
): Promise<void> => hit(eventName, id, getConsentParams(), ...paramsArgs);

const privacyPopinDisplayHit = (): Promise<void> =>
  hit(pageEvent, displayPageId, getScreenParams(privacyPopinScreenType));

const privacyAcceptAllClickHit = (): Promise<void> =>
  consentHit(clickEvent, ctaLinkId, getScreenParams(privacyPopinScreenType, "tout-accepter"));

const privacyRejectAllClickHit = (): Promise<void> =>
  consentHit(clickEvent, ctaLinkId, getScreenParams(privacyPopinScreenType, "tout-refuser"));

const privacyCustomizeConsentsClickHit = (): Promise<void> =>
  consentHit(
    clickEvent,
    ctaLinkId,
    getScreenParams(privacyPopinScreenType, "parametrez-vos-choix")
  );

const privacyCenterDisplayHit = (): Promise<void> =>
  hit(pageEvent, displayPageId, getScreenParams(privacyCenterScreenType));

const privacySaveClickHit = (): Promise<void> =>
  consentHit(clickEvent, ctaLinkId, getScreenParams(privacyCenterScreenType, "sauvegarder"));

const isTrackingOptoutPage = (): boolean => trackingOptoutScreenType === screenType;

// https://e-tf1.atlassian.net/browse/FPP-2287
export const initConsentTracking = (): void => {
  const acceptAllButtonSelector = "#popin_tc_privacy_button_2";
  const rejectAllButtonSelector = "#popin_tc_privacy_button";
  const showPrivacyCenterPopinButtonSelector = ".tc-open-privacy-center";

  delegate(document.body, "click", acceptAllButtonSelector, () => {
    privacyPopinDisplayHit();
    privacyAcceptAllClickHit();
  });

  delegate(document.body, "click", rejectAllButtonSelector, () => {
    privacyPopinDisplayHit();
    privacyRejectAllClickHit();
  });

  delegate(document.body, "click", showPrivacyCenterPopinButtonSelector, () => {
    privacyPopinDisplayHit();
    privacyCustomizeConsentsClickHit();
    privacyCenterDisplayHit();
  });

  // the save button is in an iframe => we can't listen for click event
  // but we can listen for update with privacy-center element displayed
  subscribe("consent:update", (response: TrustCommander.ConsentUpdatedResponseObject) => {
    const {
      updateEvent,
      consent: {
        categories: {
          [String(statisticsId)]: { status }
        }
      }
    } = response;
    const isPrivacyCenterOpened = document
      .querySelector("#privacy-container")
      ?.getAttribute("style");
    const triggerSaveButtonHit = updateEvent === "set" && status === "on" && isPrivacyCenterOpened;

    if (triggerSaveButtonHit) {
      privacySaveClickHit();
    }
  });

  subscribe("privacy-center:show", () => privacyCenterDisplayHit());

  if (isTrackingOptoutPage()) {
    defaultDisplayPageHit();
  }
};
