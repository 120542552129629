import { getCookie, setCookie, cookiesRefs, removeCookie } from "./cookies";

const enabledCookieValue = "1";

let enabled = getCookie(cookiesRefs.trustedMode) === enabledCookieValue;

export const isEnabled = (): boolean => enabled;

const enable = (): void => {
  setCookie(cookiesRefs.trustedMode, enabledCookieValue);
  enabled = true;
};

const disable = (): void => {
  removeCookie(cookiesRefs.trustedMode);
  enabled = false;
};

export const toggle = (): void => {
  if (isEnabled()) {
    disable();
  } else {
    enable();
  }
};

export const getCsa = (): Api.Csa => (isEnabled() ? 1 : 0);
