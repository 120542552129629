import { cloneObject } from "./lib/utils";

const [
  env,
  apiHost,
  assetHost,
  authHost,
  frontHost,
  publicPath,
  screen,
  chromeVersion,
  firefoxVersion,
  safariVersion,
  edgeVersion,
  playerLogLevel,
  playerMediaInfoBaseUrl,
  customizationId,
  statisticsId,
  efficiencyId,
  programId,
  programName,
  programGenre,
  screenType,
  isAuthenticated,
  thirdIdType,
  isActive,
  currentProfileId,
  zone,
  joinedAuthorizations,
  stripePublicKey,
  iosAppUrl,
  androidAppUrl
] = cloneObject(window.tfmData);

const authorizations = joinedAuthorizations.split(",");

export {
  env,
  apiHost,
  assetHost,
  authHost,
  frontHost,
  publicPath,
  screen,
  chromeVersion,
  firefoxVersion,
  safariVersion,
  edgeVersion,
  playerLogLevel,
  playerMediaInfoBaseUrl,
  customizationId,
  statisticsId,
  efficiencyId,
  programId,
  programName,
  programGenre,
  screenType,
  isAuthenticated,
  thirdIdType,
  isActive,
  currentProfileId,
  zone,
  authorizations,
  stripePublicKey,
  iosAppUrl,
  androidAppUrl
};
