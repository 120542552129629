import Block from "./block";
import { coverLinkClickHit } from "../lib/tms/catalog";
import { throttle } from "../lib/utils";

export default class ProgramSliderBlock extends Block {
  blockName: string;
  listElement: HTMLElement;
  listWrapperElement: HTMLElement;

  constructor(el: HTMLElement) {
    super(el);

    this.blockName = this.el.dataset.blockName;
    this.listElement = this.el.querySelector(".program_slider_list");
    this.listWrapperElement = this.listElement.parentElement;

    this.init();
  }

  init(): void {
    this.dom.delegate(this.el, "click", ".previous_button", this.scrollLeft.bind(this));
    this.dom.delegate(this.el, "click", ".next_button", this.scrollRight.bind(this));

    this.dom.on(
      this.listElement,
      "scroll",
      throttle(() => this.setScrollableState(), 250, { leading: true, trailing: true })
    );
    this.setScrollableState();

    if (this.blockName) {
      this.dom.delegate(this.el, "click", ".link", this.onProgramLinkClick.bind(this));
    }
  }

  setScrollableState(): void {
    const container = this.listElement;
    const scrollWidth = container.scrollWidth;
    const clientWidth = container.clientWidth;
    const isScrollable = scrollWidth > clientWidth;
    const scrollLeft = container.scrollLeft;

    const isScrollableLeft = isScrollable && scrollLeft > 0;
    const isScrollableRight = isScrollable && scrollLeft < scrollWidth - clientWidth;
    const value = `${isScrollableLeft ? "left" : ""} ${isScrollableRight ? "right" : ""}`;

    this.listWrapperElement.setAttribute("data-scrollable", value);
  }

  scrollLeft(): void {
    this.listElement.scrollBy({
      behavior: "smooth",
      left: -1 * Math.floor(this.listElement.clientWidth / 2)
    });
  }

  scrollRight(): void {
    this.listElement.scrollBy({
      behavior: "smooth",
      left: Math.floor(this.listElement.clientWidth / 2)
    });
  }

  getTmsProgramParams(
    coverLink: Element
  ): [programId: string, programName: string, programGenre: string] {
    const id = (<Element>coverLink.parentNode).getAttribute("data-id");
    const [, genre, name] = coverLink.getAttribute("href").split("/");

    return [id, name, genre];
  }

  onProgramLinkClick({ delegateMatch: link }: Event): void {
    coverLinkClickHit(...this.getTmsProgramParams(link), this.blockName);
  }
}
