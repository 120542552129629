import { clickEvent, defaultHitFactory, getClickScreenParams } from "./index";

const defaultHeaderClickHit = defaultHitFactory(clickEvent, "click-header");
const defaultFooterClickHit = defaultHitFactory(clickEvent, "click-footer");

export const headerLinkClickHit = (headerLinkName: string, prefix = "header-"): Promise<void> =>
  defaultHeaderClickHit(getClickScreenParams(`${prefix}${headerLinkName}`));

export const headerAccountBoxLinkClickHit = (menuLinkName: string): Promise<void> =>
  defaultHeaderClickHit(getClickScreenParams(`menu-${menuLinkName}`));

export const footerLinkClickHit = (footerLinkName: string): Promise<void> =>
  defaultFooterClickHit(getClickScreenParams(footerLinkName));
