import Block from "./block";
import { isActive } from "../page-data";
import { getOffers, hasTrialOffer } from "../lib/offer";
import { subscribe } from "../lib/user/subscription";

export default class ProgramHeaderBlock extends Block {
  constructor(el: HTMLElement) {
    super(el);
    this.init();
  }

  init(): void {
    this.initPlayButton();
    this.initSubscribeButton();
  }

  async initPlayButton(): Promise<void> {
    const playButton = <HTMLButtonElement>this.el.querySelector(".play_button");

    if (!playButton) {
      return;
    }

    const { programId, videoId } = playButton.dataset;

    this.dom.on(playButton, "click", this.onPlay.bind(this, programId, videoId));
  }

  async initSubscribeButton(): Promise<void> {
    const subscribeButton = this.el.querySelector(".subscribe_button");

    if (!subscribeButton) {
      return;
    }

    this.dom.on(subscribeButton, "click", () => subscribe());

    const offers = await getOffers();
    const trial = hasTrialOffer(offers);

    if (!trial) {
      subscribeButton.textContent = "Réactiver mon abonnement";
    }

    subscribeButton.classList.add("ready");
  }

  async onPlay(programId: string, videoId: string): Promise<void> {
    if (isActive) {
      const { play } = await import("../lib/video");

      return play(programId, videoId);
    }

    const { default: SubscriptionModal } = await import("../modals/subscriptionModal");

    new SubscriptionModal().open();
  }
}
