import Block from "./block";
import { getProgramSearchResult } from "../lib/partial";
import { searchProgramCtaClickHit } from "../lib/tms/catalog";
import { debounce } from "../lib/utils";

const minInputLength = 3;
let searchCounter = 0;
const getNextSearchCounter = () => searchCounter++;
const openedBodyAttribute = "data-header-search-box-opened";
const openedClassname = "opened";
const ariaExpandedAttribute = "aria-expanded";
const optionSelector = '[role="option"]';
const selectedAttribute = "aria-selected";
const selectedOptionSelector = `[${selectedAttribute}="true"]`;

export default class HeaderSearchBoxBlock extends Block {
  form: HTMLFormElement;
  searchButton: HTMLButtonElement;
  searchInput: HTMLInputElement;
  clearButton: HTMLButtonElement;
  resultsContainer: HTMLElement;
  currentSearchCounter: number;
  lastSearchInput: string;
  debouncedSearch: (input: string) => void;

  constructor(el: HTMLElement) {
    super(el);
    this.form = this.el.querySelector("form");
    this.searchButton = this.el.querySelector(".search_button");
    this.searchInput = this.el.querySelector(".search_input");
    this.clearButton = this.el.querySelector(".clear_button");
    this.resultsContainer = this.el.querySelector(".search_form_results");

    this.debouncedSearch = debounce(this.search.bind(this), 500);

    this.init();
  }

  init(): void {
    this.dom.on(this.form, "submit", this.onSubmit.bind(this));
    this.dom.on(this.searchButton, "click", this.onSearchButtonEvent.bind(this));
    this.dom.on(this.searchInput, "input", this.onSearchEvent.bind(this));
    this.dom.on(this.searchInput, "focus", this.onSearchEvent.bind(this));
    this.dom.on(this.searchInput, "blur", this.onSearchEvent.bind(this));
    this.dom.on(this.searchInput, "keydown", this.onSearchKeydown.bind(this));
    this.dom.on(this.clearButton, "click", this.onClearButtonEvent.bind(this));
    this.dom.on(document, "keyup", this.onDocumentKeyup.bind(this));
    this.dom.delegate(
      this.resultsContainer,
      "click",
      ".program_link",
      this.onProgramLinkClick.bind(this)
    );
    this.dom.delegate(document, "click", `[href="#${this.form.id}"]`, (event) => {
      this.el.scrollIntoView({ behavior: "smooth", block: "start" });
      this.open();
      event.preventDefault();
    });
  }

  open(): void {
    if (this.isOpened()) {
      return;
    }

    document.body.setAttribute(openedBodyAttribute, "true");
    this.el.classList.add(openedClassname);
    this.searchInput.focus();
    this.searchInput.setAttribute(ariaExpandedAttribute, "true");
  }

  close(): void {
    if (!this.isOpened()) {
      return;
    }

    document.body.removeAttribute(openedBodyAttribute);
    this.el.classList.remove(openedClassname);
    this.searchInput.setAttribute(ariaExpandedAttribute, "false");
    this.searchButton.focus();
  }

  isOpened(): boolean {
    return this.el.classList.contains(openedClassname);
  }

  getResultOptions(): HTMLElement[] {
    return [].slice.call(this.el.querySelectorAll(optionSelector));
  }

  getSelectedOption(): HTMLElement {
    return this.el.querySelector(selectedOptionSelector);
  }

  unselectOption(option: HTMLElement): void {
    if (!option) {
      return;
    }

    option.setAttribute(selectedAttribute, "false");
  }

  selectOption(option: HTMLElement): void {
    if (!option) {
      return;
    }

    this.unselectOption(this.getSelectedOption());
    option.setAttribute(selectedAttribute, "true");
  }

  selectNextOption(): void {
    const options = this.getResultOptions();
    const optionsCount = options.length;
    const selected = this.getSelectedOption();
    const selectedIndex = options.indexOf(selected);

    if (!optionsCount) {
      return;
    }

    // selected not found
    if (selectedIndex === -1) {
      return this.selectOption(options[0]);
    }

    // selected is last option
    if (selectedIndex === optionsCount - 1) {
      return;
    }

    return this.selectOption(options[selectedIndex + 1]);
  }

  selectPreviousOption(): void {
    const options = this.getResultOptions();
    const optionsCount = options.length;
    const selected = this.getSelectedOption();
    const selectedIndex = options.indexOf(selected);

    if (!optionsCount) {
      return;
    }

    // selected not found
    if (selectedIndex === -1) {
      return;
    }

    // selected is first option
    if (selectedIndex === 0) {
      return this.unselectOption(selected);
    }

    return this.selectOption(options[selectedIndex - 1]);
  }

  onSubmit(event: Event): void {
    const selected = <HTMLLinkElement>this.getSelectedOption();

    // link selected option
    if (selected && selected.href) {
      event.preventDefault();
      this.programLinkClickHit(selected);
      window.location.href = selected.href;
    }

    this.close();
    // implicit submit
  }

  onSearchButtonEvent(): void {
    if (!this.isOpened()) {
      return this.open();
    }
  }

  onSearchEvent(event: Event): void {
    const inputValue = this.searchInput.value.trim();

    if (event.type === "blur" && !inputValue) {
      return this.close();
    }

    this.debouncedSearch(inputValue);
  }

  onSearchKeydown(event: KeyboardEvent): void {
    const key = event.key.toLowerCase();
    const up = "arrowup";
    const down = "arrowdown";

    // not a special key key
    if (![up, down].includes(key)) {
      return;
    }

    event.preventDefault();

    if (key === down) {
      return this.selectNextOption();
    }

    if (key === up) {
      return this.selectPreviousOption();
    }
  }

  onClearButtonEvent(): void {
    this.searchInput.value = "";
    this.close();
  }

  onDocumentKeyup(event: KeyboardEvent): void {
    const key = event.key.toLowerCase();

    if (key === "escape" || key === "esc") {
      this.close();
    }
  }

  getTmsProgramParams(
    programLink: Element
  ): [programId: string, programName: string, programGenre: string] {
    const id = (<Element>programLink.parentNode).getAttribute("data-id");
    const [, genre, name] = programLink.getAttribute("href").split("/");

    return [id, name, genre];
  }

  programLinkClickHit(link: HTMLLinkElement): void {
    searchProgramCtaClickHit(...this.getTmsProgramParams(link));
  }

  onProgramLinkClick({ delegateMatch: link }: Event): void {
    this.programLinkClickHit(<HTMLLinkElement>link);
  }

  displayLoader(): void {
    this.el.classList.add("loading");
  }

  hideLoader(): void {
    this.el.classList.remove("loading");
  }

  cleanResults(): void {
    this.el.classList.remove("filled");
    this.resultsContainer.innerHTML = "";
  }

  async search(input: string): Promise<void> {
    const hasMinRequirements = input && input.length >= minInputLength;
    const currentSearchCounter = getNextSearchCounter();
    this.currentSearchCounter = currentSearchCounter;

    // skip if input has not changed
    if (this.lastSearchInput === input) {
      return;
    }

    this.lastSearchInput = input;
    this.cleanResults();

    // not allowed to search
    if (!hasMinRequirements) {
      return;
    }

    this.displayLoader();

    const html = await getProgramSearchResult(input);

    // this is not the last search call
    if (currentSearchCounter !== this.currentSearchCounter) {
      return;
    }

    this.hideLoader();
    this.el.classList.add("filled");
    this.resultsContainer.innerHTML = html;
  }
}
