import * as dom from "./dom";

interface ScrollerOptions {
  direction?: "right" | "left" | "bottom" | "top";
  step?: number;
  element?: HTMLElement;
}

// Scroller element constructor.
// assume that requestAnimationFrame is available or polyfilled.
class Scroller {
  static defaultOptions: ScrollerOptions = {
    direction: "right", // 'right', 'left', 'bottom', 'top'
    step: 5,
    element: null
  };

  options: ScrollerOptions;
  scrollProp: "scrollLeft" | "scrollTop";
  modifier: 1 | -1;
  scrollable: HTMLElement;
  element: HTMLElement;
  _scrolling: boolean;

  constructor(scrollable: HTMLElement, options: ScrollerOptions = null) {
    this.options = Object.assign({}, Scroller.defaultOptions, options);
    this.scrollProp = ["right", "left"].includes(this.options.direction)
      ? "scrollLeft"
      : "scrollTop";
    this.modifier = ["left", "top"].includes(this.options.direction) ? -1 : 1;
    this.scrollable = scrollable;
    this.element = this.options.element || dom.addClass(document.createElement("div"), "scroller");
    dom.on(this.element, "mouseenter", this.start.bind(this));
    dom.on(this.element, "mouseleave", this.stop.bind(this));
  }

  move(): void {
    const value = this.modifier * this.options.step;
    this.scrollable[this.scrollProp] += value;
  }

  walk(): void {
    if (this._scrolling) {
      this.move();
      window.requestAnimationFrame(this.walk.bind(this));
    }
  }

  start(): void {
    this._scrolling = true;
    this.walk();
  }

  stop(): void {
    this._scrolling = false;
  }
}

export default Scroller;
