import { efficiencyId } from "../page-data";
import { subscribe } from "../actions";
import { getCookie, setCookie, cookiesRefs, removeCookie } from "./cookies";
import { getConsentGrantedValue } from "./trust-commander";
import { getUrlSearchParam } from "./utils";

const storageKey = "user_origin";
const tagCommanderCategoryId = efficiencyId;
const cookieTTL = 2592000; // 30 days

let value: string = null;

export const getOrigin = (): string => value;

const save = async (newValue: string): Promise<void> => {
  if (!newValue) {
    return;
  }

  // save in memory
  value = newValue;

  // save in session
  window.sessionStorage.setItem(storageKey, newValue);

  // save in cookies if granted
  const isConsentGranted = await getConsentGrantedValue(tagCommanderCategoryId);

  if (isConsentGranted) {
    setCookie(cookiesRefs.origin, newValue, cookieTTL);
  }
};

// sources :
// 1. url
// 2. sessionStorage
// 3. cookies (if user agreed)
export const initOrigin = (): void => {
  const newValue = getUrlSearchParam("origin");
  value = newValue || window.sessionStorage.getItem(storageKey) || getCookie(cookiesRefs.origin);

  if (newValue) {
    save(newValue);
  }

  // consent update
  subscribe("consent:update", async () => {
    const isConsentGranted = await getConsentGrantedValue(tagCommanderCategoryId);

    if (isConsentGranted) {
      if (value) {
        setCookie(cookiesRefs.origin, value, cookieTTL);
      }
    } else {
      removeCookie(cookiesRefs.origin);
    }
  });
};
