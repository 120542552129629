import "./public-path";
import "./mandatory";
import { frontHost } from "./page-data";
import { delegate } from "./lib/dom";
import { requestIdleCallback } from "./lib/utils";
import { initConsent, isConsentSet, setOptinAll } from "./lib/trust-commander";
import { initBlockingModalGuard } from "./lib/user/authorizations";
import { initAuth } from "./lib/auth";
import { initAutoScreensets } from "./lib/gigya-auto-screensets";
import { initParentalSpaceGuard } from "./lib/user/preferences";
import { initOrigin } from "./lib/origin";
import { runJsBlocks } from "./blocks/block";
import { initConsentTracking } from "./lib/tms/consent";
import { initCatalogTracking } from "./lib/tms/catalog";
import { initParentalSpaceTracking } from "./lib/tms/parental-space";
import { initPromoCodeTracking } from "./lib/tms/promo-code";
import { initProfilesTracking } from "./lib/tms/profiles";

const checkImplicitConsent = async (href: string): Promise<void> => {
  const url = new URL(href, window.location.href).href;
  const isFrontUrl = href && href[0] !== "#" && url.indexOf(frontHost) === 0;
  const isNotCookiePolicyPage = isFrontUrl && !url.includes("/politique-cookies");
  const isConsetSet = await isConsentSet();

  if (isFrontUrl && isNotCookiePolicyPage && !isConsetSet) {
    setOptinAll();
  }
};

const listenToLinks = (): void => {
  delegate(document, "click", "a", ({ delegateMatch: link }) => {
    const href = link.getAttribute("href"); // raw attribute value
    checkImplicitConsent(href);
  });
};

const listenToFakeLinks = (): void => {
  delegate(document, "click", "[data-href]", (event: MouseEvent) => {
    const { delegateMatch: link, ctrlKey, metaKey, shiftKey } = event;
    const href = link.getAttribute("data-href");
    let target = link.getAttribute("data-target");

    if (!href) {
      return;
    }

    if (ctrlKey || metaKey || shiftKey) {
      target = "_blank";
    }

    event.preventDefault();
    checkImplicitConsent(href);
    window.open(href, target || "_self");
  });
};

const initPageTracking = (): void => {
  initConsentTracking();
  initCatalogTracking();
  initParentalSpaceTracking();
  initPromoCodeTracking();
  initProfilesTracking();
};

// init sequence
initConsent();
initBlockingModalGuard();
initAuth();
initAutoScreensets();
initParentalSpaceGuard();
listenToLinks();
listenToFakeLinks();
initOrigin();
runJsBlocks(document.body);
requestIdleCallback(() => initPageTracking());
