import { subscribe } from "../actions";
import Block from "./block";
import {
  getCurrentProfileId,
  getSelectedProfileId,
  setSelectedProfileId
} from "../lib/user/profiles";
import { getAccountDropdownProfiles, getProfileAvatar } from "../lib/partial";

const openedAttribute = "aria-expanded";

export default class NavBlock extends Block {
  toggleButton: HTMLElement;
  profilesFlag: boolean;

  constructor(el: HTMLElement) {
    super(el);
    this.toggleButton = this.el.querySelector(".nav_toggle_button");
    this.init();
  }

  init(): void {
    this.dom.on(this.toggleButton, "click", this.onClickToggle.bind(this));
    this.dom.on(this.el, "click", this.closeNav.bind(this));
    this.dom.on(this.el, "keydown", this.onKeydown.bind(this));
    this.dom.on(this.el, "focusout", this.onFocusout.bind(this));
    this.dom.on(this.el, "transitionend", this.onTransitionEnd.bind(this));
    this.setActiveLink();
    this.dom.delegate(this.el, "click", ".profile_switch_button", this.onSelectProfile.bind(this));
    subscribe("profiles:update", () => {
      this.refreshProfiles();
      this.refreshCurrentProfileAvatar();
    });
  }

  isOpened(): boolean {
    return this.toggleButton.getAttribute(openedAttribute) === "true";
  }

  openNav(): void {
    this.toggleButton.setAttribute(openedAttribute, "true");
    this.dom.once(this.el, "click", () => this.closeNav());

    // render profiles only once
    if (!this.profilesFlag) {
      this.profilesFlag = true;
      this.refreshProfiles();
    }
  }

  closeNav(): void {
    this.toggleButton.setAttribute(openedAttribute, "false");
  }

  onClickToggle(event: Event): void {
    event.stopPropagation();
    this.isOpened() ? this.closeNav() : this.openNav();
  }

  onKeydown({ key }: KeyboardEvent): void {
    if (key === "Escape") {
      this.toggleButton.focus();
      this.closeNav();
    }
  }

  onFocusout({ relatedTarget }: FocusEvent): void {
    const isNode = relatedTarget instanceof Node;

    if (!(isNode && this.el.contains(relatedTarget))) {
      this.closeNav();
    }
  }

  onTransitionEnd({ propertyName }: TransitionEvent): void {
    // opening/closing nav transition has ended
    if (propertyName === "transform") {
      this.toggleButton.dataset.contentVisible = String(this.isOpened());
    }
  }

  async onSelectProfile({ delegateMatch: button }: Event): Promise<void> {
    const profileId = button.getAttribute("data-profile-id");

    await setSelectedProfileId(profileId, true);
    window.location.reload();
  }

  setActiveLink(): void {
    const pagePathname = window.location.pathname;

    const activeLink = Array.from(this.el.querySelectorAll(".nav_item a")).find(
      (link: HTMLAnchorElement) => {
        const { pathname } = link;
        const altPathname = link.dataset.altPathname;

        return [pathname, altPathname].includes(pagePathname);
      }
    );

    if (activeLink) {
      activeLink.classList.add("active");
    }
  }

  async refreshProfiles(): Promise<void> {
    const profilesList = this.el.querySelector(".profiles_list");

    if (!profilesList) {
      return;
    }

    const html = await getAccountDropdownProfiles();
    profilesList.innerHTML = html;
  }

  async refreshCurrentProfileAvatar(): Promise<void> {
    const profileAvatar = this.toggleButton.querySelector(".profile_avatar");

    if (!profileAvatar) {
      return;
    }

    const profileId = await getCurrentProfileId();
    const selectedProfileId = getSelectedProfileId();
    const className = `small${selectedProfileId ? "" : " temporary"}`;
    const html = await getProfileAvatar(profileId, { className });
    profileAvatar.outerHTML = html;
  }
}
