import { createElement } from "../lib/dom";
import {
  isTrialOffersItem,
  findMonthlyOffersItem,
  findAnnualOffersItem,
  getFinalOffer
} from "../lib/offer";

export const htmlToElement = (html: string): Element => {
  const div = createElement("div");
  div.innerHTML = html;

  return div.firstElementChild;
};

export const nl2br = (text: string): DocumentFragment => {
  const frag = document.createDocumentFragment();
  const lines = text.split("\n");
  const maxIndex = lines.length - 1;

  lines.forEach((line, index) => {
    frag.append(line);

    if (index !== maxIndex) {
      frag.append(createElement("br"));
    }
  });

  return frag;
};

const extRegex = /^(?<basename>.+)\.(?<imageExt>[^.]+)$/;
const gabIdRegex = /^(?<basename>.+?)(?:_[0-9]+)?\.(?<ext>[^.]+)$/;

export const addImageGab = (
  src: string,
  gabId: number,
  ext: "jpg" | "png" | "webp" = null
): string => {
  if (!src) {
    return "";
  }

  const url = new URL(src);

  url.protocol = "https";

  // Fix s.tf1.fr host (helios legacy)
  if (url.hostname === "s.tf1.fr") {
    url.hostname = "s.tfoumax.fr";
  }

  // replace extension
  if (ext) {
    url.pathname = url.pathname.replace(extRegex, (...args) => {
      const { basename, imageExt } = args.pop();

      return `${basename}.${ext || imageExt}`;
    });
  }

  if (!gabId) {
    return url.toString();
  }

  // replace/insert gab id
  url.pathname = url.pathname.replace(gabIdRegex, (...args) => {
    const { basename, ext } = args.pop();

    return `${basename}_${gabId}.${ext}`;
  });

  return url.toString();
};

export const pluralize = (
  count: number | string,
  singular: string,
  plural = `${singular}s`
): string => {
  count = Number(count);
  return count > 1 ? plural : singular;
};

const numberPadding = (value: number, length = 2, fillString = "0"): string =>
  String(value).padStart(length, fillString);

export const formatPrice = (price: number, currency = "€"): string => {
  const priceString = (price / 100).toFixed(2).replace(".", ",");
  return priceString + currency;
};

type DateFormat = "short";

export const formatDate = (input: string | number | Date, format: DateFormat = "short"): string => {
  const date = new Date(input);

  if (date.toString() === "Invalid Date") {
    return "";
  }

  if (format === "short") {
    return `${numberPadding(date.getDate())}/${numberPadding(
      date.getMonth() + 1
    )}/${date.getFullYear()}`;
  }
};

const periodicityLabels: Record<Api.OfferPeriodicity, string> = {
  yearly: "an",
  "half-yearly": "semestre",
  quarterly: "trimestre",
  monthly: "mois",
  weekly: "semaine",
  daily: "jour",
  unknown: ""
};

export const getPeriodLabel = (periodicity: Api.OfferPeriodicity): string =>
  periodicityLabels[periodicity];

const getDurationCountLabel = (value: number, isFemaleGender = false): string =>
  value === 1 ? `un${isFemaleGender ? "e" : ""}` : String(value);

const getDurationLabel = (
  years: number,
  months: number,
  weeks: number,
  days: number
): [label: string, count: number, isFemaleGender: boolean] => {
  if (years) {
    return [`${getDurationCountLabel(years)} ${pluralize(years, "an")}`, years, false];
  }

  if (months) {
    return [`${getDurationCountLabel(months)} mois`, months, false];
  }

  if (weeks) {
    // exception for 1 week
    return weeks === 1
      ? ["7 jours", 7, false]
      : [`${getDurationCountLabel(weeks, true)} ${pluralize(weeks, "semaine")}`, weeks, true];
  }

  if (days) {
    return [`${getDurationCountLabel(days)} ${pluralize(days, "jour")}`, days, false];
  }

  return ["", 0, false];
};

export const getOfferDurationLabel = ({
  duration: { years, months, weeks, days }
}: Api.Offer): [label: string, count: number, isFemaleGender: boolean] =>
  getDurationLabel(years, months, weeks, days);

export const getTrialDurationLabel = (offers: Api.Offers): string => {
  // find first trial offer because all trial offers should have same duration
  const offersItem = offers.find(isTrialOffersItem);

  if (!offersItem) {
    return "";
  }

  const {
    starter: {
      duration: { years, months, weeks, days }
    }
  } = offersItem;

  // 1 month => custom label
  const [durationLabel, durationCount, isFemaleGender] =
    months === 1 ? ["1er mois", 1, false] : getDurationLabel(years, months, weeks, days);
  const suffixLabel = pluralize(durationCount, `offert${isFemaleGender ? "e" : ""}`);

  return `${durationLabel} ${suffixLabel}`;
};

// return a number
const getMonthCoefficient = ({ years, months, weeks, days }: Api.OfferDuration): number => {
  if (years) {
    return years * 12;
  }

  if (months) {
    return months;
  }

  if (weeks) {
    return (weeks * 1) / (365 / 12 / 7);
  }

  if (days) {
    return (days * 1) / (365 / 12);
  }

  throw new Error("Invalid offer duration");
};

// Returns discount comparing an offer to a reference offer
const getOfferDiscount = (
  { price, duration }: Api.Offer,
  { price: referencePrice, duration: referenceDuration }: Api.Offer
): number =>
  Math.max(
    0,
    (referencePrice -
      (price * getMonthCoefficient(referenceDuration)) / getMonthCoefficient(duration)) /
      referencePrice
  );

const getDiscountPercentLabel = (discount: number): string => {
  const percent = discount > 0 ? Math.floor(discount * 100) : 0;
  // rounded value should be a multiple of 5 (psychological effect)
  const roundedPercent = percent - (percent % 5);

  if (!roundedPercent) {
    return "";
  }

  return `${percent > roundedPercent ? "plus de " : ""}${roundedPercent}%`;
};

export const getOffersItemDiscountPercentLabel = (
  offer: Api.Offer,
  referenceOffer: Api.Offer
): string => {
  if (!(offer && referenceOffer)) {
    return "";
  }

  return getDiscountPercentLabel(getOfferDiscount(offer, referenceOffer));
};

// TODO : FIX when multiple offers (monthly and/or yearly and/or half-yearly and/or ...)
export const getOffersLabel = (offers: Api.Offers, format = "default"): string => {
  const monthlyOffer = getFinalOffer(findMonthlyOffersItem(offers));
  const annualOffer = getFinalOffer(findAnnualOffersItem(offers));
  const monthlyOfferLabel = monthlyOffer ? `${formatPrice(monthlyOffer.price)}/mois` : "";
  const annualOfferLabel = annualOffer ? `${formatPrice(annualOffer.price)}/an` : "";
  const discountLabel = getOffersItemDiscountPercentLabel(monthlyOffer, annualOffer);

  // none
  if (!monthlyOfferLabel && !annualOfferLabel) {
    return "";
  }

  if (format === "long:with_trial_duration") {
    const trialDurationLabel = getTrialDurationLabel(offers);

    return `${trialDurationLabel ? `${trialDurationLabel}, puis ` : ""}${monthlyOfferLabel}${
      monthlyOffer && annualOffer ? " ou " : ""
    }${annualOfferLabel}, sans engagement de durée`;
  }

  if (format.startsWith("long")) {
    if (monthlyOfferLabel && annualOfferLabel) {
      return `${monthlyOfferLabel} sans engagement
      ou ${annualOfferLabel}${discountLabel ? `, soit ${discountLabel} de réduction` : ""}`;
    }

    // custom format
    if (format === "long:program_header") {
      return `${getTrialDurationLabel(offers)}, puis seulement ${
        monthlyOfferLabel || annualOfferLabel
      }, sans engagement de durée`;
    }

    return `${monthlyOfferLabel || annualOfferLabel}, sans engagement de durée`;
  }

  // default
  return `${monthlyOfferLabel}${monthlyOffer && annualOffer ? " ou " : ""}${annualOfferLabel}`;
};
