import { screenType } from "../../page-data";
import {
  clickEvent,
  defaultHit,
  defaultDisplayPageHit,
  defaultCtaPageHit,
  getClickScreenParams
} from "./index";

const promoCodeScreenType = "code-promo";
const submitErrorId = "code-error";
const submitSuccessId = "code-success";
const submitInvalidId = "code-invalid";

export const promoCodeSubmitButtonClickHit = (): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams("valider"));

export const promoCodeSubmitError = (): Promise<void> => defaultHit(clickEvent, submitErrorId);

export const promoCodeSubmitSuccess = (): Promise<void> => defaultHit(clickEvent, submitSuccessId);

export const promoCodeSubmitInvalid = (): Promise<void> => defaultHit(clickEvent, submitInvalidId);

const isPromoCodePage = (): boolean => promoCodeScreenType === screenType;

export const initPromoCodeTracking = (): void => {
  if (!isPromoCodePage()) {
    return;
  }

  defaultDisplayPageHit();
};
