import { screenType } from "../../page-data";
import {
  clickEvent,
  defaultHit,
  defaultDisplayPageHit,
  defaultCtaThumbnailHit,
  defaultCtaPageHit,
  getClickScreenParams,
  getProgramParams,
  getProgramParamsFromProgram,
  getVideoParamsFromProgramVideo,
  getVideoParamsFromVideo
} from "./index";
import { delegate } from "../dom";

export const coverLinkClickHit = (
  programId: string,
  programName: string,
  programGenre: string,
  blockName: string
): Promise<void> =>
  defaultCtaThumbnailHit(
    getClickScreenParams(blockName),
    getProgramParams(programId, programName, programGenre)
  );

export const coverAddFavoriteButtonClickHit = (program: Api.Program): Promise<void> =>
  defaultHit(
    clickEvent,
    "add-to-bookmarks",
    getClickScreenParams("add-list-program"),
    getProgramParamsFromProgram(program)
  );

export const coverRemoveFavoriteButtonClickHit = (program: Api.Program): Promise<void> =>
  defaultHit(
    clickEvent,
    "remove-from-bookmarks",
    getClickScreenParams("remove-list-program"),
    getProgramParamsFromProgram(program)
  );

export const coverOverviewLinkClickHit = (
  program: Api.Program,
  video: Api.ProgramVideo = null,
  resumePlayback = false
): Promise<void> =>
  defaultCtaThumbnailHit(
    getClickScreenParams("clic-preview"),
    getProgramParamsFromProgram(program),
    video ? getVideoParamsFromProgramVideo(video, false, resumePlayback) : {}
  );

export const discoverCatalogLinkClickHit = (): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams("decouvrir-catalogue"));

export const storeLinkClickHit = (storeName: string): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams(storeName));

export const homeBottomSubscribeLinkClickHit = (): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams("essai-offert-bas-de-page"));

export const showcaseLinkClickHit = (clickableElementName: string): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams(clickableElementName));

export const showcaseProgramLinkClickHit = (programName: string): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams("cta-cover"), { program_programName: programName });

export const programVideoLinkClickHit = (blockName: string, video: Api.Video): Promise<void> =>
  defaultCtaThumbnailHit(
    getClickScreenParams(blockName),
    video ? getVideoParamsFromVideo(video, false, false) : {}
  );

export const searchProgramCtaClickHit = (
  programId: string,
  programName: string,
  programGenre: string
): Promise<void> =>
  defaultHit(
    clickEvent,
    "click-search",
    getClickScreenParams("vignette-search", "search"),
    getProgramParams(programId, programName, programGenre)
  );

const isCatalogPage = (): boolean =>
  ["accueil", "home-connecte", "favoris", "showpage"].includes(screenType) ||
  /^home-replay-/.test(screenType);

export const initCatalogTracking = (): void => {
  if (!isCatalogPage()) {
    return;
  }

  delegate(document.body, "click", ".catalog_link", () => discoverCatalogLinkClickHit());
  delegate(document.body, "click", ".device_app_link", ({ delegateMatch }: Event) =>
    storeLinkClickHit(delegateMatch.getAttribute("data-store"))
  );

  defaultDisplayPageHit();
};
