import {
  env,
  customizationId,
  statisticsId,
  efficiencyId,
  programId as pageProgramId,
  programName as pageProgramName,
  programGenre as pageProgramGenre,
  screenType as pageScreenType,
  isAuthenticated,
  isActive
} from "../../page-data";
import { userAgent, OS, getTmsDeviceName } from "../device";
import { getTC } from "../tag-commander";
import { getConsentGrantedValue } from "../trust-commander";
import { isNewUser, isSubscribedNewsletter } from "../auth";
import { getAuthenticationProvider, getUserData } from "../user";
import { getCookie } from "../cookies";
import { slugify } from "../utils";

type DefaultParams = DataLayer.Params.Default;

type Params =
  | DefaultParams
  | DataLayer.Params.Page
  | DataLayer.Params.Medium
  | DataLayer.Params.Device
  | DataLayer.Params.Consent
  | DataLayer.Params.User
  | DataLayer.Params.Program
  | DataLayer.Params.Video;

export const pageEvent = "page";
export const clickEvent = "click";

export const displayPageId = "display-page";
export const ctaLinkId = "cta-link";
const ctaPageId = "cta-page";
const ctaThumbnailId = "cta-vignette";

export const periodicityMapping: Record<Api.OfferPeriodicity, string> = {
  yearly: "annuel",
  "half-yearly": "semestriel",
  quarterly: "trimestriel",
  monthly: "mensuel",
  weekly: "hebdomadaire",
  daily: "quotidien",
  unknown: "autre"
};

const isLogEnabled = () => getCookie("tCdebugLib2") === "2";

const isNullish = (value: unknown) => value === null || typeof value === "undefined";

const removeNullishParams = <K>(params: DefaultParams): Partial<K> =>
  Object.entries(params).reduce((filteredParams, [key, value]) => {
    if (!isNullish(value)) {
      filteredParams[key] = value;
    }

    return filteredParams;
  }, {});

const mergeParams = async (...paramsArgs: (Params | Promise<Params>)[]): Promise<DefaultParams> => {
  const params = await Promise.all(paramsArgs);
  return Object.assign({}, ...params);
};

export const hit = async (
  eventName: DataLayer.EventName,
  id: string,
  ...paramsArgs: (Params | Promise<Params>)[]
): Promise<void> => {
  const datalayer: DefaultParams = await mergeParams(
    getPageParams(),
    getMediumParams(),
    getDeviceParams(),
    { id },
    ...paramsArgs
  );
  const tC = await getTC();

  window.tc_vars = datalayer;
  tC.container.reload({ events: { [eventName]: [{}, {}] } });

  if (isLogEnabled()) {
    console.groupCollapsed(
      `%c TMS event ${eventName} with id ${id} `,
      "background: #0ff; color: #000;"
    );
    console.log(JSON.stringify(datalayer, null, 2));
    console.groupEnd();
  }
};

export const defaultHit = (
  eventName: DataLayer.EventName,
  id: string,
  ...paramsArgs: (Params | Promise<Params>)[]
): Promise<void> => hit(eventName, id, getConsentParams(), getUserParams(), ...paramsArgs);

export const defaultHitFactory =
  (
    eventName: DataLayer.EventName,
    id: string
  ): ((...paramsArgs: (Params | Promise<Params>)[]) => Promise<void>) =>
  (...paramsArgs: (Params | Promise<Params>)[]) =>
    defaultHit(eventName, id, ...paramsArgs);

export const defaultDisplayPageHit = defaultHitFactory(pageEvent, displayPageId);
export const defaultCtaLinkHit = defaultHitFactory(clickEvent, ctaLinkId);
export const defaultCtaPageHit = defaultHitFactory(clickEvent, ctaPageId);
export const defaultCtaThumbnailHit = defaultHitFactory(clickEvent, ctaThumbnailId);

const getTmsUid = () => getCookie("tc_unique_id");

export const getScreenParams = (
  screenType: string,
  clickableElementName: string = null
): Partial<DataLayer.Params.Screen> =>
  removeNullishParams({
    screen_screenType: screenType,
    screen_clickableElementName: clickableElementName
  });

export const getClickScreenParams = (
  clickableElementName: string,
  screenType: string = null
): Partial<DataLayer.Params.Screen> =>
  removeNullishParams({
    screen_screenType: screenType,
    screen_clickableElementName: clickableElementName
  });

const padding = (n) => (n < 10 ? `0${n}` : String(n));

const formatDate = (input: string | number | Date): string => {
  const date = new Date(input);
  return `${date.getFullYear()}-${padding(date.getMonth() + 1)}-${padding(date.getDate())}`;
};

const getPageParams = async (): Promise<DataLayer.Params.Page> => {
  let screenType = pageScreenType;

  if (screenType === "home-connecte") {
    screenType = `${screenType}${isActive ? "" : "-non"}-abonne`;
  }

  return {
    screen_screenType: screenType,
    ...removeNullishParams({
      program_programId: pageProgramId,
      program_programName: pageProgramName,
      program_programGenre: pageProgramGenre
    })
  };
};

const getMediumParams = (): DataLayer.Params.Medium => ({
  medium_platform: "web",
  medium_mediumName: "tfoumax",
  medium_environment: env
});

const getDeviceParams = (): DataLayer.Params.Device => ({
  device_deviceName: getTmsDeviceName(),
  device_deviceOs: OS,
  device_userAgent: userAgent
});

export const getConsentParams = async (): Promise<DataLayer.Params.Consent> => ({
  consent_necessary: true,
  consent_efficiency: await getConsentGrantedValue(efficiencyId),
  consent_customisation: await getConsentGrantedValue(customizationId),
  consent_statistics: await getConsentGrantedValue(statisticsId)
});

const getAuthenticationMean = async (): Promise<
  DataLayer.Params.User["user_authenticationMean"]
> => {
  const provider = await getAuthenticationProvider();
  const mapping = {
    orange: "orange",
    "bytel-bachat": "bouygues",
    "gigya:facebook": "fb",
    "gigya:apple": "apple",
    "gigya:site": "email"
  };

  return mapping[provider] || "unknown";
};

const getUserSubscriptionParams = (userData: User.Data): DataLayer.Params.UserSubscription => {
  const {
    subscription,
    subscription: { isActive }
  } = userData;

  if (!isActive) {
    return null;
  }

  const { offerId, startAt, endAt, dmpOfferType, offerPeriodicity = "" } = subscription;

  return {
    user_subscriptionStatus: dmpOfferType,
    user_subscriptionType: periodicityMapping[offerPeriodicity],
    user_subscriptionID: offerId,
    user_subscriptionStartDate: formatDate(startAt),
    user_subscriptionEndDate: formatDate(endAt)
  };
};

const getUserParams = async (): Promise<DataLayer.Params.User> => {
  const isValidUser = isAuthenticated;

  // no user data
  if (!isValidUser) {
    return null;
  }

  const [userData, authenticationMean, optinNews] = await Promise.all([
    getUserData(),
    getAuthenticationMean(),
    isSubscribedNewsletter()
  ]);

  const {
    authentication: { type, shaExternalId },
    account: { shaMail },
    subscription: { isActive }
  } = userData;

  return {
    user_status: isActive ? "abonne" : "non-abonne",
    user_authenticationType: isNewUser() ? "creation" : "login",
    user_authenticationMode: "manuel",
    user_authenticationMean: authenticationMean,
    user_gigyaId: type === "gigya" ? shaExternalId : "",
    ["user_email-1"]: shaMail,
    user_optinNews: optinNews,
    user_tagCoUid: getTmsUid(),
    ...getUserSubscriptionParams(userData)
  };
};

export const getProgramParams = (
  programId: string,
  programName: string,
  programGenre: string
): DataLayer.Params.Program => ({
  program_programId: programId,
  program_programName: programName,
  program_programGenre: programGenre
});

export const getProgramParamsFromProgram = ({
  id,
  slug,
  type
}: Api.Program): DataLayer.Params.Program => getProgramParams(id, slug, type);

const getVideoParams = (
  videoName: string,
  watId: number,
  duration: number,
  playAlong: false | "auto" | "manuel",
  resumePlayback: boolean,
  playerSize: "full-screen" | "standard" = "standard"
): DataLayer.Params.Video => ({
  content_video_videoName: slugify(videoName),
  content_video_videoRefvideoId: String(watId),
  content_video_videoType: "replay",
  content_video_duration: duration,
  content_video_playAlong: playAlong,
  content_video_resumePlayback: resumePlayback,
  content_video_playerType: "html5",
  content_video_playerSize: playerSize
});

export const getVideoParamsFromProgramVideo = (
  { shorttitle, watId, duration }: Api.ProgramVideo,
  playAlong: false | "auto" | "manuel",
  resumePlayback: boolean
): DataLayer.Params.Video => getVideoParams(shorttitle, watId, duration, playAlong, resumePlayback);

export const getVideoParamsFromVideo = (
  { shortTitle, watId, duration }: Api.Video,
  playAlong: false | "auto" | "manuel",
  resumePlayback: boolean
): DataLayer.Params.Video => getVideoParams(shortTitle, watId, duration, playAlong, resumePlayback);
