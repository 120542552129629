// see http://underscorejs.org/#throttle for documentation
interface ThrottleOptions {
  leading?: boolean;
  trailing?: boolean;
}

export const throttle = function (
  func: (...args: unknown[]) => void,
  wait: number,
  options: ThrottleOptions = {}
): (...args: unknown[]) => void {
  let context, args, result;
  let timeout = null;
  let previous = 0;

  const later = () => {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);

    if (!timeout) {
      context = args = null;
    }
  };

  return function (...callArgs) {
    const now = Date.now();

    if (!previous && options.leading === false) {
      previous = now;
    }

    const remaining = wait - (now - previous);
    context = this;
    args = callArgs;

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        window.clearTimeout(timeout);
        timeout = null;
      }

      previous = now;
      result = func.apply(context, args);

      if (!timeout) {
        context = args = null;
      }
    } else if (!timeout && options.trailing !== false) {
      timeout = window.setTimeout(later, remaining);
    }

    return result;
  };
};

// see http://underscorejs.org/#debounce for documentation
export const debounce = function (
  func: (...args: unknown[]) => void,
  wait: number,
  immediate = false
): (...args: unknown[]) => void {
  let timeout, args, context, timestamp, result;

  const later = function () {
    const now = Date.now();
    const last = now - timestamp;

    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;

      if (!immediate) {
        result = func.apply(context, args);

        if (!timeout) context = args = null;
      }
    }
  };

  return function (...lastArgs) {
    context = this;
    args = lastArgs;
    timestamp = Date.now();

    const callNow = immediate && !timeout;

    if (!timeout) timeout = setTimeout(later, wait);

    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
};

// Promise helpers
export const promiseSeries = (tasks: (() => Promise<unknown>)[] = []): Promise<unknown[]> =>
  tasks.reduce(async (prevTaskPromise, task) => {
    const results = await prevTaskPromise;
    return results.concat(await task());
  }, Promise.resolve([]));

export const getUrlSearchParam = (key: string): string => {
  let value = null;

  window.location.search
    .substr(1)
    .split("&")
    .some((item) => {
      const tmp = item.split("=");
      if (tmp[0] !== key) {
        return false;
      }
      // found
      value = decodeURIComponent(tmp[1]);
      return true;
    });

  return value;
};

export const addQueryParamToUrl = (url: string, name: string, value = "", encode = true): string =>
  `${url + (!url.includes("?") ? "?" : "&")}${encodeURIComponent(name)}=${
    encode ? encodeURIComponent(value) : value
  }`;

type HTMLFormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export const serializeForm = (form: HTMLFormElement): Record<string, string> => {
  const obj = {};
  // no form
  if (!(form && form.nodeName === "FORM")) {
    return obj;
  }

  Array.from(form.elements).forEach((element: HTMLFormControlElement) => {
    const name = element.name;

    if (!name) {
      return;
    }
    // checkbox / radio
    if (
      element instanceof HTMLInputElement &&
      ["checkbox", "radio"].includes(element.type) &&
      !element.checked
    ) {
      return;
    }
    // multi select
    if (element instanceof HTMLSelectElement && element.type === "select-multiple") {
      Array.from(element.options).forEach(({ selected, value }) => {
        if (selected) {
          obj[name] = value;
        }
      });
      return;
    }
    // default
    obj[name] = element.value;
  });

  return obj;
};

// use anchor tag to get absolute url
export const getAbsoluteUrl = (url: string): string => {
  const a = document.createElement("a");
  a.href = url;
  return a.href;
};

export const downloadFile = (
  content: BlobPart,
  filename = "data.txt",
  type = "text/plain"
): void => {
  const file = new Blob([content], { type });
  const downloader = document.createElement("a");

  downloader.href = window.URL.createObjectURL(file);
  downloader.download = filename;
  downloader.click();
};

export const removeAccents = (string: string): string =>
  string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const slugify = (string: string): string =>
  removeAccents(string)
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "") // remove all chars except letters, numbers and spaces
    .trim()
    .replace(/(\s|-)+/g, "-");

export const isFinite =
  Number.isFinite ||
  ((value: unknown): boolean => typeof value === "number" && window.isFinite(value));

export const removeUndefinedKeys = <T extends Record<string, unknown>>(obj: T): Partial<T> =>
  Object.keys(obj).reduce((memo, key) => {
    const value = obj[key];

    if (typeof value !== "undefined") {
      memo[key] = value;
    }

    return memo;
  }, {});

declare global {
  interface Window {
    /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/structuredClone) */
    structuredClone<T = unknown>(value: T, options?: StructuredSerializeOptions): T;
  }
}

const cloneObjectPolyfill = <T>(object: T): T => JSON.parse(JSON.stringify(object));

export const cloneObject = window.structuredClone || cloneObjectPolyfill;

export const requestIdleCallback =
  window.requestIdleCallback ||
  function (cb) {
    const start = Date.now();
    return window.setTimeout(
      () =>
        cb({
          didTimeout: false,
          timeRemaining: () => Math.max(0, 50 - (Date.now() - start))
        }),
      1
    );
  };

export const cancelIdleCallback =
  window.cancelIdleCallback ||
  function (id) {
    window.clearTimeout(id);
  };
