import Block from "./block";
import { isActive } from "../page-data";
import { getOffers, hasTrialOffer } from "../lib/offer";
import { subscribe } from "../lib/user/subscription";
import { headerLinkClickHit } from "../lib/tms/layout";
import { subscriptionCtaTemplate } from "../templates/subscription_cta";

export default class SubscriptionCtaBlock extends Block {
  constructor(el: HTMLElement) {
    super(el);

    (async () => {
      // no subscription cta if already subscribed
      if (isActive) {
        return;
      }

      await this.render();
      this.init();
    })();
  }

  async render(): Promise<void> {
    const offers = await getOffers();
    const trial = hasTrialOffer(offers);
    const {
      dataset: { linkTestid }
    } = this.el;
    this.el.appendChild(subscriptionCtaTemplate({ trial, testId: linkTestid }));
  }

  init(): void {
    this.dom.delegate(this.el, "click", ".subscription_cta_button", () => this.onClick());
  }

  onClick(): void {
    const context = this.el.getAttribute("data-context");

    if (context === "header") {
      headerLinkClickHit("essayez-gratuitement", "");
    }

    subscribe();
  }
}
