import { authHost } from "../page-data";
import { getCookie, setCookie, removeCookie, cookiesRefs } from "./cookies";
import { removeSelectedProfile } from "./user/profiles";
import { post } from "./fetch";

interface SavedPairingCode {
  code: string;
  loginSuccessUrl: string;
}

export const check = (code: string): Promise<void> => {
  const url = `${authHost}/pairing/check`;
  return post<void>(url, { data: { code } });
};

export const pair = async (code: string, type: string): Promise<void> => {
  const url = `${authHost}/web/pairing`;

  await post<void>(url, {
    data: { code, type },
    withCredentials: true
  });

  // reset profile selection
  removeSelectedProfile();
};

export const save = (code: string, loginSuccessUrl: string = window.location.href): void => {
  const ttl = 600; // 10min
  const value = JSON.stringify([code, loginSuccessUrl]);
  setCookie(cookiesRefs.pairingCode, value, ttl);
};

const getPairingCodeCookie = (): string => getCookie(cookiesRefs.pairingCode);

export const load = (): SavedPairingCode => {
  const value = getPairingCodeCookie();
  if (!value) {
    return null;
  }
  const [code, loginSuccessUrl] = <string[]>JSON.parse(value);
  return { code, loginSuccessUrl };
};

export const isSaved = (): boolean => !!getPairingCodeCookie();

export const remove = (): void => removeCookie(cookiesRefs.pairingCode);
