import { screenType } from "../../page-data";
import { defaultDisplayPageHit, defaultCtaPageHit, getClickScreenParams } from "./index";

const parentalSpaceScreenType = "espace-parent";

export const parentalCodeActivationButtonHit = (activate: boolean): Promise<void> =>
  defaultCtaPageHit(
    getClickScreenParams(
      activate ? "activer-controle-parental" : "desactiver-controle-parental",
      parentalSpaceScreenType
    )
  );

export const subscriptionManagementSubscribeLinkHit = (): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams("s-abonner"));

export const subscriptionManagementPromoCodeButtonHit = (): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams("utiliser-code-cadeau"));

const isParentalSpacePage = (): boolean =>
  [
    "espace-parent",
    "code-parental",
    "abonnement",
    "annulation-abonnement",
    "mes-donnees",
    "mes-infos"
  ].includes(screenType);

export const initParentalSpaceTracking = (): void => {
  if (!isParentalSpacePage()) {
    return;
  }

  defaultDisplayPageHit();
};
