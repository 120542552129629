declare global {
  interface Event {
    delegateMatch?: Element;
  }
}

/**
 * DOM helpers
 */

// Event binding
// Important: Returns a function to be called when unbind is needed
export const on = (
  el: EventTarget,
  type: string,
  listener: EventListener,
  capture = false
): (() => void) => {
  el.addEventListener(type, listener, capture);
  return el.removeEventListener.bind(el, type, listener, capture);
};

// bind only once
export const once = (
  el: EventTarget,
  type: string,
  listener: EventListener,
  capture = false
): (() => void) => {
  const off = on(
    el,
    type,
    (event) => {
      off(); // unbind
      listener(event);
    },
    capture
  );
  return off;
};

// Event delegation.
// Selector matching child is available through event.delegateMatch
// (impossible to override event.currentTarget).
export const delegate = (
  el: EventTarget,
  type: string,
  selector: string,
  listener: EventListener,
  capture = false
): (() => void) => {
  return on(
    el,
    type,
    (event) => {
      const { target } = event;
      let match: Element = null;
      if (target instanceof Element) {
        match = target.closest(selector);
      }
      if (match) {
        // keep a match reference in the event object
        event.delegateMatch = match;
        listener.call(match, event);
      }
    },
    capture
  );
};

export const addClass = <T extends Element>(el: T, value = ""): T => {
  value = value.trim();
  if (!value) {
    return el;
  } // empty value
  el.classList.add(value);
  return el;
};

export const removeClass = <T extends Element>(el: T, value = ""): T => {
  value = value.trim();
  if (!value) {
    return el;
  } // empty value
  el.classList.remove(value);
  return el;
};

export const offset = (el: HTMLElement): { top: number; left: number } => {
  let left = 0;
  let top = 0;
  while (el instanceof HTMLElement && !Number.isNaN(el.offsetLeft) && !Number.isNaN(el.offsetTop)) {
    left += el.offsetLeft - el.scrollLeft;
    top += el.offsetTop - el.scrollTop;
    el = <HTMLElement>el.offsetParent;
  }
  return { top, left };
};

export const remove = (el: Node): Node => {
  if (el.parentNode) {
    el.parentNode.removeChild(el);
  }
  return el;
};

export const empty = (el: Node): Node => {
  while (el.firstChild) {
    el.removeChild(el.firstChild);
  }
  return el;
};

export const createText = (value: string): Text => document.createTextNode(value);

type AttributeValues = string | number | boolean;
type GenericAttributes = Record<string, AttributeValues>;

interface Attributes {
  [name: string]: AttributeValues | GenericAttributes;
  raw?: GenericAttributes;
  dataset?: GenericAttributes;
}

type SetAttributeMethod = "assign" | "setter" | "dataset";

const setAttribute = <T extends HTMLElement>(
  element: T,
  name: string,
  value: AttributeValues,
  setMethod: SetAttributeMethod = "assign"
): T => {
  if (setMethod === "setter") {
    element.setAttribute(name, String(value));
  } else if (setMethod === "dataset") {
    element.dataset[name] = String(value);
  } else {
    element[name] = value;
  }

  return element;
};

const setAttributes = <T extends HTMLElement>(
  element: T,
  attributes: GenericAttributes,
  setMethod: SetAttributeMethod = "assign"
): T => {
  for (const name in attributes) {
    setAttribute(element, name, attributes[name], setMethod);
  }

  return element;
};

export const createElement = (
  tag: keyof HTMLElementTagNameMap,
  className = "",
  attributes: Attributes = {},
  children: (Node | string)[] = [],
  callback?: (element: HTMLElement) => void
): HTMLElement => {
  const element = document.createElement(tag);
  const { raw, dataset, ...attrs } = attributes;

  if (className) {
    element.className = className;
  }

  if (raw) {
    setAttributes(element, raw, "setter");
  }

  if (dataset) {
    setAttributes(element, dataset, "dataset");
  }

  setAttributes(element, <GenericAttributes>attrs);

  for (const child of children) {
    if (child) {
      element.appendChild(typeof child === "string" ? createText(child) : child);
    }
  }

  if (callback) {
    callback(element);
  }

  return element;
};

export const queryFocusables = (container: HTMLElement): NodeListOf<HTMLElement> =>
  container.querySelectorAll<HTMLElement>(
    'a[href]:not([disabled]), area[href]:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]'
  );
