import { chromeVersion, firefoxVersion, safariVersion, edgeVersion } from "../page-data";

const iosReg = /iPhone|iPad|iPod/i;
const androidReg = /Android/i;
const windowsPhoneReg = /Windows Phone/i;
const windowsReg = /Windows/i;
const MacOSReg = /(Mac_PowerPC)|(Macintosh)/i;
const LinuxReg = /(Linux)|(X11)/i;

const ChromeVersionReg = /Chrome\/(\d+)/i;
const ChromeIOSVersionReg = /CriOS\/(\d+)/i;
const FirefoxVersionReg = /Firefox\/(\d+)/i;
const EdgeVersionReg = /Edge\/(\d+)/i;
const EdgeChromiumVersionReg = /Edg\/(\d+)/i;
const SafariVersionReg = /Version\/(\d+\.\d+)/i;

const versionGetter = (regex: RegExp) => (): number => {
  const match = userAgent.match(regex);
  return match && Number(match[1]);
};

const vendor = window.navigator.vendor;
export const userAgent = window.navigator.userAgent;

const googleVendor = "Google Inc.";

const iOSValue = "iOS";
const androidValue = "Android";
const windowsPhoneValue = "WindowsPhone";
const windowsValue = "Windows";
const macOSValue = "MacOS";
const linuxValue = "Linux";
const unknownValue = "Unknown";

export const unsupportedReason = "unsupported";
export const outdatedReason = "outdated";

export const isTouch = (): boolean => "ontouchstart" in window || "onmsgesturechange" in window;

export const OS = (() => {
  if (windowsPhoneReg.test(userAgent)) {
    return windowsPhoneValue;
  }
  if (iosReg.test(userAgent)) {
    return iOSValue;
  }
  if (androidReg.test(userAgent)) {
    return androidValue;
  }
  if (windowsReg.test(userAgent)) {
    return windowsValue;
  }
  if (MacOSReg.test(userAgent)) {
    return macOSValue;
  }
  if (LinuxReg.test(userAgent)) {
    return linuxValue;
  }
  return unknownValue;
})();

export const isIOS = (): boolean => OS === iOSValue;
export const isAndroid = (): boolean => OS === androidValue;
export const isWindowsPhone = (): boolean => OS === windowsPhoneValue;
export const isMobile = (): boolean => isIOS() || isAndroid() || isWindowsPhone();
export const isDesktop = (): boolean => !isMobile();
export const isWindows = (): boolean => OS === windowsValue;
export const isMacOS = (): boolean => OS === macOSValue;
export const isLinux = (): boolean => OS === linuxValue;

// --- IE & Edge/ChromiumEdge ---
export const isIE = (): boolean => userAgent.includes("MSIE ") || userAgent.includes("Trident");

export const isEdge = (): boolean => EdgeVersionReg.test(userAgent);
export const getEdgeVersion = versionGetter(EdgeVersionReg);

export const isChromiumEdge = (): boolean => EdgeChromiumVersionReg.test(userAgent);
export const getChromiumEdgeVersion = versionGetter(EdgeChromiumVersionReg);

// --- Chrome ---
// chromium based browser
const isChromium = () => !!window.chrome;

export const isChrome = (): boolean =>
  isChromium() &&
  vendor === googleVendor && // created by Google Inc.
  !window.opr && // not opera built on chromium
  !isChromiumEdge(); // not edge built on chromium

export const getChromeVersion = versionGetter(ChromeVersionReg);

export const isChromeIOS = (): boolean => userAgent.includes("CriOS");
export const getChromeIOSVersion = versionGetter(ChromeIOSVersionReg);

// --- Firefox ---
export const isFirefox = (): boolean => userAgent.includes("Firefox");
export const getFirefoxVersion = versionGetter(FirefoxVersionReg);

// --- Safari ---
export const isSafari = (): boolean => !isChromium() && userAgent.includes("Safari");
export const getSafariVersion = versionGetter(SafariVersionReg);

// supported browser detection
export interface UnsupportedBrowserDetails {
  name: string;
  reason: string;
}

export const getUnsupportedBrowserDetails = (): UnsupportedBrowserDetails => {
  const chromeSupportedVersion = chromeVersion;
  const firefoxSupportedVersion = firefoxVersion;
  const edgeSupportedVersion = edgeVersion;
  const safariSupportedVersion = safariVersion;
  const isSupportedBrowser =
    isChrome() || isFirefox() || isChromiumEdge() || isEdge() || isSafari();

  // IE
  if (!isSupportedBrowser) {
    const browserName = isIE() ? "Internet Explorer" : null;
    return { name: browserName, reason: unsupportedReason };
  }

  // Chrome iOS
  if (chromeSupportedVersion && isChromeIOS()) {
    return getChromeIOSVersion() < chromeSupportedVersion
      ? { name: "Chrome", reason: outdatedReason }
      : null;
  }

  // Chrome
  if (chromeSupportedVersion && isChrome()) {
    return getChromeVersion() < chromeSupportedVersion
      ? { name: "Chrome", reason: outdatedReason }
      : null;
  }

  // Firefox
  if (firefoxSupportedVersion && isFirefox()) {
    return getFirefoxVersion() < firefoxSupportedVersion
      ? { name: "Firefox", reason: outdatedReason }
      : null;
  }

  // Chromium Edge
  if (edgeSupportedVersion && isChromiumEdge()) {
    return getChromiumEdgeVersion() < edgeSupportedVersion
      ? { name: "Edge", reason: outdatedReason }
      : null;
  }

  // Edge
  if (edgeSupportedVersion && isEdge()) {
    return getEdgeVersion() < edgeSupportedVersion
      ? { name: "Edge", reason: outdatedReason }
      : null;
  }

  // Safari
  if (safariSupportedVersion && isSafari()) {
    return getSafariVersion() < safariSupportedVersion
      ? { name: "Safari", reason: outdatedReason }
      : null;
  }
};

export const isSupportedBrowser = (): boolean => !getUnsupportedBrowserDetails();

const tmsDesktopValue = "desktop";
const tmsTabletValue = "tablette";
const tmsMobileValue = "mobile";

// simple rules to guess device type (not 100% accuracy)
export const getTmsDeviceName = (): string => {
  // guess with os and user agent
  if ([windowsValue, macOSValue, linuxValue].includes(OS)) {
    return tmsDesktopValue;
  }

  if (
    /iPad/.test(userAgent) ||
    (OS === androidValue && !/mobi/i.test(userAgent)) ||
    /tablet/i.test(userAgent)
  ) {
    return tmsTabletValue;
  }

  if ([iOSValue, androidValue, windowsPhoneValue].includes(OS) || /mobi/i.test(userAgent)) {
    return tmsMobileValue;
  }

  // else guess with screen width (or height in landscape)
  const width = Math.min(window.screen.width, window.screen.height);

  if (width < 768) {
    return tmsMobileValue;
  }

  if (width <= 1280) {
    return tmsTabletValue;
  }

  return tmsDesktopValue;
};
