import { getTC } from "./tag-commander";
import { publish, subscribe } from "../actions";
import { getAuthenticationProvider } from "./user";

const initStub = (): void => {
  window.caReady = window.caReady || [];
  window.cact = (...args: TrustCommander.cactParameters): void => {
    window.caReady.push(args);
  };
};

const cact = (...args: TrustCommander.cactParameters) => {
  if (!window.cact) {
    initStub();
  }

  // hack to fix trust commander initialization
  if (window?.tC?.privacy?.cookieData === null) {
    window.tC.privacy.init();
  }

  window.cact(...args);
};

const getConsent = (): Promise<TrustCommander.ConsentResponseObject> =>
  new Promise((resolve) => cact("consent.get", resolve));

export const isConsentSet = async (): Promise<boolean> => {
  const consent = await getConsent();
  return consent.consent.status !== "unset";
};

export const getConsentGrantedValue = async (catId: number): Promise<boolean> => {
  const consent = await getConsent();
  return consent.consent.categories?.[String(catId)]?.status === "on";
};

export const setOptinAll = (): void => cact("consent.update", { status: "all-on" });

export const setOptoutAll = (): void => cact("consent.update", { status: "all-off" });

export const showPrivacyCenter = async (): Promise<void> => {
  const tC = await getTC();
  publish("privacy-center:show");
  tC.privacyCenter.showPrivacyCenter();
};

export const initConsent = (): void => {
  cact("consent.onUpdate", (response: TrustCommander.ConsentUpdatedResponseObject) =>
    publish("consent:update", response)
  );

  subscribe("login:success", async () => {
    const authenticationProvider = await getAuthenticationProvider();

    // force optout on orange login
    if (authenticationProvider === "orange") {
      setOptoutAll();
    }
  });
};
