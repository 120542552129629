import { screenType } from "../../page-data";
import { defaultDisplayPageHit, defaultCtaPageHit, getClickScreenParams } from "./index";

export const defaultProfileButtonClickHit = (): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams("enfant"));

export const customProfileButtonClickHit = (): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams("custom-profil"));

export const createProfileButtonClickHit = (): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams("create-new-profil"));

export const accountSettingsLinkClickHit = (): Promise<void> =>
  defaultCtaPageHit(getClickScreenParams("parametrer-compte"));

const isProfilesPage = (): boolean => "choix-profil" === screenType;

export const initProfilesTracking = (): void => {
  if (!isProfilesPage()) {
    return;
  }

  defaultDisplayPageHit();
};
