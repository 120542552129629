import { apiHost, screen } from "../../page-data";
import { publish } from "../../actions";
import { authUserFetch } from "./index";
import { getTimeLimit, unlimitedTimeLimitValue, isParentStrict } from "./preferences";

// define timer helper
const timerValues: Map<User.ProfileId, Api.UserTimer["timer"]> = new Map();

// return timer
export const getTimer = async (profileId: User.ProfileId = null): Promise<number> => {
  const timeLimit = await getTimeLimit(profileId);

  // no need to fetch if no time limit
  if (timeLimit === unlimitedTimeLimitValue) {
    return Infinity;
  }

  const cachedValue = timerValues.get(profileId);

  // fetch timer only once
  if (typeof cachedValue === "number") {
    return cachedValue;
  }

  const url = `${apiHost}/userData/timer${profileId ? `?profileId=${profileId}` : ""}`;
  const { timer: newValue } = await authUserFetch<Api.UserTimer>(
    "GET",
    url,
    { screen },
    { withCredentials: true }
  );

  timerValues.set(profileId, newValue);
  return newValue;
};

export const decrementTimer = async (
  duration: number,
  profileId: User.ProfileId = null
): Promise<void> => {
  if (!duration || isNaN(duration)) {
    throw new Error("Duration is not defined");
  }

  const timeLimit = await getTimeLimit(profileId);

  // no need to decrement if no time limit
  if (timeLimit === unlimitedTimeLimitValue) {
    return;
  }

  const url = `${apiHost}/userData/timer/decrement${profileId ? `?profileId=${profileId}` : ""}`;
  const { timer: newValue } = await authUserFetch<Api.UserTimer>(
    "GET",
    url,
    { duration, screen },
    { withCredentials: true }
  );

  timerValues.set(profileId, newValue);
  publish("timer:update", newValue, profileId);
};

export const resetTimer = async (profileId: User.ProfileId = null): Promise<void> => {
  const url = `${apiHost}/userData/timer/reset${profileId ? `?profileId=${profileId}` : ""}`;
  // user must be a parent to reset timer
  await isParentStrict();
  const { timer: newValue } = await authUserFetch<Api.UserTimer>(
    "GET",
    url,
    { screen },
    { withCredentials: true }
  );

  timerValues.set(profileId, newValue);
  publish("timer:update", newValue, profileId);
};
