import { isActive, authorizations } from "../../page-data";

const streamAuthorizationKey = "video-stream";
const paymentCreditCardAuthorizationKey = "payment-creditcard";

const blockedWhitelistUrlPrefixes = [
  "/espace-parents",
  "/qui-sommes-nous",
  "/conditions-generales",
  "/mentions-legales",
  "/politique-protection-donnees",
  "/politique-cookies",
  "/copyrights"
];

const can = (key: User.AuthorizationValue): boolean => authorizations.includes(key);

export const canStream = (): boolean => can(streamAuthorizationKey);
export const canSubscribeOnWeb = (): boolean => can(paymentCreditCardAuthorizationKey);

// subscribed but not authorized to stream
export const isBlocked = (): boolean => isActive && !canStream();

export const initBlockingModalGuard = async (): Promise<void> => {
  // only for "catalog" pages
  const path = window.location.pathname;
  const skip = blockedWhitelistUrlPrefixes.some((urlPrefix) => path.startsWith(urlPrefix));

  if (skip) {
    return;
  }

  if (isBlocked()) {
    const { default: BlockingModal } = await import("../../modals/blockingModal");
    new BlockingModal("default").open();
  }
};
