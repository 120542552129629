import Block, { runJsBlocks } from "./block";
import { subscribe } from "../actions";
import { throttle } from "../lib/utils";
import { isTouch } from "../lib/device";
import * as dom from "../lib/dom";
import { getProgramOverview } from "../lib/partial";
import Scroller from "../lib/scroller";
import { coverLinkClickHit } from "../lib/tms/catalog";
import { carouselArrowsTemplate } from "../templates/carousel_arrows";
import { spinnerTemplate } from "../templates/spinner";

const OVERVIEW_TIMEOUT = 800;
const OVERVIEW_WIDTH = 300;

export default class CoverListBlock extends Block {
  carousel: boolean;
  blockName: string;
  list: HTMLElement;
  overview: HTMLElement;
  overviewables: HTMLElement[];
  overviewed: HTMLElement;
  leftScroller: HTMLElement;
  rightScroller: HTMLElement;
  _overviewTimeout: number;

  constructor(el: HTMLElement) {
    super(el);

    this.carousel = this.el.hasAttribute("data-carousel");
    this.blockName = this.el.getAttribute("data-block-name");
    this.list = this.el.querySelector(".list");
    this.overview = this.createOverview();
    this.overviewables = this.list ? Array.from(this.list.querySelectorAll(".cover_link")) : [];
    this.overviewed = null;

    this.init();
    return this;
  }

  init(): void {
    // empty
    if (!this.list) {
      return;
    }

    // carousel
    if (this.carousel) {
      if (!isTouch()) {
        this.appendScrollers();
      }

      dom.on(window, "resize", throttle(this.updateScrollable.bind(this), 1000));
      this.updateScrollable();
    }

    // overview & favorite button
    this.dom.on(this.el, "mouseenter", this.onMouseenter.bind(this), true);
    this.dom.on(this.el, "mouseleave", this.onMouseleave.bind(this), true);
    dom.on(this.overview, "mouseleave", this.onMouseleave.bind(this));

    subscribe("favorites:add", this.onFavoritesUpdate.bind(this, "added"));
    subscribe("favorites:remove", this.onFavoritesUpdate.bind(this, "removed"));

    // tracking
    if (this.blockName) {
      this.dom.delegate(this.el, "click", ".cover_link", this.onCoverLinkClick.bind(this));
    }
  }

  createOverview(): HTMLElement {
    const overview = document.createElement("div");
    overview.classList.add("overview");
    return overview;
  }

  appendScrollers(): void {
    const container = this.list.parentNode;
    this.leftScroller = new Scroller(this.list, { direction: "left" }).element;
    this.rightScroller = new Scroller(this.list, { direction: "right" }).element;
    this.updateScrollable();
    dom.addClass(this.leftScroller, "left");
    this.leftScroller.appendChild(carouselArrowsTemplate({ direction: "left" }));
    container.appendChild(this.leftScroller);
    dom.addClass(this.rightScroller, "right");
    this.rightScroller.appendChild(carouselArrowsTemplate({ direction: "right" }));
    container.appendChild(this.rightScroller);
  }

  updateScrollable(): void {
    if (this.leftScroller && this.rightScroller) {
      if (this.list.scrollWidth > this.list.clientWidth) {
        dom.removeClass(this.leftScroller, "hidden");
        dom.removeClass(this.rightScroller, "hidden");
      } else {
        dom.addClass(this.leftScroller, "hidden");
        dom.addClass(this.rightScroller, "hidden");
      }
    }
  }

  onMouseenter(event: MouseEvent): void {
    const { target } = event;

    if (!(target instanceof HTMLElement)) {
      return;
    }

    // skip if target is not overviewable or is the overviewed element
    if (!this.overviewables.includes(target) || target === this.overviewed) {
      return;
    }

    const id = (<Element>target.parentNode).getAttribute("data-id");

    this.addFavoriteButton(target, id);
    this.showOverview(target, id);
  }

  onMouseleave(event: MouseEvent): void {
    const { target, relatedTarget } = event;

    if (!(target instanceof HTMLElement && relatedTarget instanceof HTMLElement)) {
      return;
    }

    // skip if no overviewed element
    if (!this.overviewed) {
      return;
    }

    // skip if target is not overviewed element or the overview
    if (![this.overviewed, this.overview].includes(target)) {
      return;
    }

    // do nothing if mouse enter overview or the overviewed
    if (
      this.overview === relatedTarget ||
      this.overview.contains(relatedTarget) ||
      this.overviewed === relatedTarget ||
      this.overviewed.contains(relatedTarget)
    ) {
      return;
    }

    this.hideOverview();
  }

  onFavoritesUpdate(action: "added" | "removed", programId: string): void {
    Array.from(this.list.querySelectorAll(`:scope > [data-id="${programId}"]`)).forEach(
      (element) => {
        element.setAttribute("data-favorites-update", action);
      }
    );
  }

  getTmsProgramParams(
    coverLink: Element
  ): [programId: string, programName: string, programGenre: string] {
    const id = (<Element>coverLink.parentNode).getAttribute("data-id");
    const [, genre, name] = coverLink.getAttribute("href").split("/");

    return [id, name, genre];
  }

  onCoverLinkClick({ delegateMatch: link }: Event): void {
    coverLinkClickHit(...this.getTmsProgramParams(link), this.blockName);
  }

  async addFavoriteButton(element: HTMLElement, programId: string): Promise<void> {
    // add favorite button only once
    if (element.hasAttribute("data-has-favorite-button")) {
      return;
    }

    const { favoriteIconButtonTemplate } = await import("../templates/favorite_icon_button");

    const listItem = element.parentElement;
    const testIdIndex = Array.from(listItem.parentElement.children).indexOf(listItem);
    const testId = `button_cover_add_delete_favorites[${testIdIndex}]`;

    element.setAttribute("data-has-favorite-button", "");
    element.appendChild(favoriteIconButtonTemplate({ programId, context: "cover", testId }));
    runJsBlocks(element);
  }

  showOverview(element: HTMLElement, programId: string): void {
    this.overviewed = element;
    element.classList.add("overviewed");

    // delayed overview appending
    this._overviewTimeout = window.setTimeout(async () => {
      this.appendOverview(element);
      this.overview.classList.add("loading");
      this.overview.innerHTML = "";
      this.overview.appendChild(spinnerTemplate());

      const html = await getProgramOverview(programId);
      this.overview.classList.remove("loading");
      this.overview.innerHTML = html;
      runJsBlocks(this.overview);
    }, OVERVIEW_TIMEOUT);
  }

  // append overview to this.el instead of element because inside element
  // the overview would be truncated by the overflow:hidden of the list
  appendOverview(element: HTMLElement): void {
    const refOffset = this.dom.offset(this.el);
    const refWidth = this.el.clientWidth;
    const scrollLeft = (<HTMLElement>element.parentNode.parentNode).scrollLeft;
    const offset = dom.offset(element);
    const relativeLeft = offset.left - refOffset.left - scrollLeft;
    // move the overview to the right of the element if space
    // is missing on the left
    if (relativeLeft < OVERVIEW_WIDTH) {
      dom.addClass(this.overview, "right_sided");
      this.overview.style.left = `${relativeLeft + element.offsetWidth}px`;
      this.overview.style.right = "auto";
    } else {
      dom.removeClass(this.overview, "right_sided");
      this.overview.style.right = `${refWidth - relativeLeft}px`;
      this.overview.style.left = "auto";
    }
    this.overview.style.top = `${offset.top - refOffset.top}px`;
    this.el.appendChild(this.overview);
  }

  hideOverview(): void {
    if (this._overviewTimeout) {
      window.clearTimeout(this._overviewTimeout);
    }

    this.overviewed.classList.remove("overviewed");
    this.overviewed = null;
    dom.remove(this.overview);
  }
}
