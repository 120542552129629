import { frontHost, apiHost, isAuthenticated as isAuthenticatedValue } from "../../page-data";
import { subscribe } from "../../actions";
import { parseJSON, fetch, get, post, AjaxOptions } from "../fetch";
import { getAuthAccount, logout } from "../auth";

type userFetchOptions<T> = Omit<AjaxOptions<T>, "requestId" | "parse" | "data">;

const userFetch = <T>(
  method: string,
  url: string,
  data: AjaxOptions<T>["data"] = null,
  options: userFetchOptions<T> = {}
): Promise<T> => {
  const requestId = `${url}:${JSON.stringify(data)}`;
  const opt = Object.assign(
    {
      requestId,
      parse: parseJSON,
      data
    },
    options
  );
  return fetch<T>(method, url, opt);
};

// fetch with authentication required
export const authUserFetch = async <T>(
  method: string,
  url: string,
  data: AjaxOptions<T>["data"] = null,
  options: userFetchOptions<T> = {}
): Promise<T | never> => {
  await isAuthenticatedStrict();
  return userFetch<T>(method, url, data, options);
};

let userData: User.Data = null;

export const getUserData = async (): Promise<User.Data> => {
  if (userData) {
    return userData;
  }

  const response = await userFetch<User.Data>("GET", `${frontHost}/user`);

  if (typeof response !== "object" && response !== null) {
    throw new Error("User response type error");
  }

  userData = response;

  return userData;
};

export const isAuthenticated = async (): Promise<boolean> => isAuthenticatedValue;

type AuthenticationProviders =
  | "gigya:site"
  | "gigya:facebook"
  | "gigya:apple"
  | "orange"
  | "bytel-bachat"
  | "unknown";

export const getAuthenticationProvider = async (): Promise<AuthenticationProviders> => {
  const [userData, gigyaAccount] = await Promise.all([getUserData(), getAuthAccount()]);
  const {
    authentication: { type = "unknown" }
  } = userData;

  if (type === "gigya") {
    const provider = gigyaAccount.loginProvider;
    const value = <"gigya:site" | "gigya:facebook" | "gigya:apple">`gigya:${provider}`;
    return value;
  }

  return type;
};

// rejects promise if user is not authenticated
export const isAuthenticatedStrict = async (): Promise<true | never> => {
  const is = await isAuthenticated();

  if (!is) {
    throw new Error("User is not authenticated");
  }

  return is;
};

const cleanLogout = (): void => {
  userData = null;
};

export const getAccount = async (): Promise<User.AccountData> => {
  const { account } = await getUserData();
  return account;
};

export const getShortId = async (): Promise<string> => {
  const account = await getAccount();
  return account?.shortId || "";
};

export const getEmail = async (): Promise<string> => {
  const account = await getAccount();
  return account?.email || "";
};

export const getShaMail = async (): Promise<string> => {
  const account = await getAccount();
  return account?.shaMail || "";
};

export const deleteAccount = async (): Promise<void> => {
  await isAuthenticatedStrict();

  const url = `${apiHost}/auth/gigya/delete`;
  await get(url, { withCredentials: true });

  logout();
};

export const isEligibleForOfferId = async (offerId: string): Promise<boolean> =>
  get<boolean>(`/user/eligibility?offerId=${offerId}`, {
    parse: parseJSON,
    withCredentials: true
  });

export const createPlayingToken = async (): Promise<string> => {
  const url = `${apiHost}/userData/playing/token`;
  const { token } = await post<Api.PlayingToken>(url, { withCredentials: true, parse: parseJSON });
  return token;
};

// init
// update user data when user logs out before page reload
subscribe("logout:success", () => cleanLogout());
