import Block from "./block";
import { isActive } from "../page-data";
import { getVideo } from "../lib/api";
import { programVideoLinkClickHit } from "../lib/tms/catalog";

const videoIdAttribute = "data-video-id";
const blockNameAttribute = "data-block-name";

export default class ProgramVideosBlock extends Block {
  constructor(el: HTMLElement) {
    super(el);
    this.init();
  }

  init(): void {
    // open subscription modal
    this.dom.delegate(this.el, "click", `[${videoIdAttribute}]`, (event: Event): void => {
      const { delegateMatch: videoLink } = event;
      event.preventDefault();
      event.stopPropagation();
      this.onClick(videoLink);
    });

    // season tabs
    this.dom.delegate(
      this.el,
      "click",
      ".program_season_button",
      this.onSeasonTabChange.bind(this)
    );

    // css pagination
    this.dom.delegate(this.el, "click", ".more_button", this.onShowNextPage.bind(this));
  }

  async onClick(videoLink: Element): Promise<void> {
    const videoId = videoLink.getAttribute(videoIdAttribute);

    this.trackVideoClick(videoLink);

    if (isActive) {
      const programId = this.el.dataset.programId;
      const { play } = await import("../lib/video");

      play(programId, videoId);
      return;
    }

    const { default: SubscriptionModal } = await import("../modals/subscriptionModal");

    new SubscriptionModal().open();
  }

  onSeasonTabChange(event: Event): void {
    const { delegateMatch: selectedTabButton } = event;

    Array.from(selectedTabButton.parentNode.children).forEach((button) => {
      button.setAttribute("aria-selected", button === selectedTabButton ? "true" : "false");
    });

    const selectedTabPanelId = selectedTabButton.getAttribute("aria-controls");
    const selectedTabPanel = document.getElementById(selectedTabPanelId);

    if (!selectedTabPanel) {
      throw new Error("Missing season tab panel");
    }

    Array.from(selectedTabPanel.parentNode.children).forEach((tabPanel) => {
      const isActive = selectedTabPanelId === tabPanel.id;

      tabPanel.setAttribute("aria-expanded", isActive ? "true" : "false");

      if (isActive) {
        tabPanel.removeAttribute("hidden");
      } else {
        tabPanel.setAttribute("hidden", "");
      }
    });
  }

  onShowNextPage(event: Event): void {
    const { delegateMatch: moreButton } = event;
    const programSeasonVideos = <HTMLElement>moreButton.parentNode;
    const seasonVideos = programSeasonVideos.querySelector(".video_list").children;
    const seasonVideoCount = seasonVideos.length;
    const page = Number(programSeasonVideos.dataset.page);
    const pageLimit = Number(programSeasonVideos.dataset.pageLimit);
    const newPage = page + 1;
    const isFull = seasonVideoCount <= newPage * pageLimit;
    const newFocusedVideo = <HTMLElement | undefined>seasonVideos[page * pageLimit];

    // video display is handled in css
    programSeasonVideos.setAttribute("data-page", String(newPage));

    if (isFull) {
      moreButton.classList.add("hidden");
    }

    if (newFocusedVideo) {
      newFocusedVideo.querySelector("button")?.focus();
    }
  }

  async trackVideoClick(videoLink: Element): Promise<void> {
    const videoId = videoLink.getAttribute(videoIdAttribute);
    const blockName = videoLink.closest(`[${blockNameAttribute}]`).getAttribute(blockNameAttribute);
    const video = await getVideo(videoId);

    programVideoLinkClickHit(blockName, video);
  }
}
