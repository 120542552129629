import Block from "./block";
import { subscribe } from "../actions";
import {
  getCurrentProfileId,
  getSelectedProfileId,
  setSelectedProfileId
} from "../lib/user/profiles";
import { getAccountDropdownProfiles, getProfileAvatar } from "../lib/partial";
import { headerAccountBoxLinkClickHit } from "../lib/tms/layout";

const accountBoxLinkAttribute = "data-account-box-link-name";

export default class AccountBoxBlock extends Block {
  dropdownButton: HTMLButtonElement;
  profilesFlag: boolean;

  constructor(el: HTMLElement) {
    super(el);
    this.dropdownButton = this.el.querySelector(".authenticated_dropdown_button");
    this.init();
  }

  init(): void {
    // dom/app events
    if (this.dropdownButton) {
      this.dom.on(this.dropdownButton, "click", this.toggleDropdown.bind(this));
      this.dom.on(this.el, "mouseenter", this.open.bind(this));
      this.dom.on(this.el, "mouseleave", this.close.bind(this));
      this.dom.on(this.el, "keydown", this.onKeydown.bind(this));
      this.dom.on(this.el, "focusout", this.onFocusout.bind(this));
    }

    this.dom.delegate(
      this.el,
      "click",
      `[${accountBoxLinkAttribute}]`,
      this.onLinkClick.bind(this)
    );

    this.dom.delegate(this.el, "click", ".profile_switch_button", this.onSelectProfile.bind(this));

    subscribe("profiles:update", () => {
      this.refreshProfiles();
      this.refreshCurrentProfileAvatar();
    });
  }

  renderProfiles(): void {
    // render profiles only once
    if (!this.profilesFlag) {
      this.profilesFlag = true;
      this.refreshProfiles();
    }
  }

  isOpened(): boolean {
    return this.dropdownButton.classList.contains("active");
  }

  open(): void {
    this.dropdownButton.classList.add("active");
    this.dropdownButton.setAttribute("aria-expanded", "true");
    this.renderProfiles();
  }

  close(): void {
    this.dropdownButton.classList.remove("active");
    this.dropdownButton.setAttribute("aria-expanded", "false");
  }

  toggleDropdown(): void {
    this.isOpened() ? this.close() : this.open();
  }

  onEscape(): void {
    this.dropdownButton.focus();
    this.close();
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === "Escape") {
      this.onEscape();
    }
  }

  onFocusout(event: FocusEvent): void {
    const target = event.relatedTarget;
    const isNode = target instanceof Node;

    if (!(isNode && this.el.contains(target))) {
      this.close();
    }
  }

  async onSelectProfile({ delegateMatch: button }: Event): Promise<void> {
    const profileId = button.getAttribute("data-profile-id");

    await setSelectedProfileId(profileId, true);
    window.location.reload();
  }

  async refreshProfiles(): Promise<void> {
    const profilesList = this.el.querySelector(".profiles_list");

    if (!profilesList) {
      return;
    }

    const html = await getAccountDropdownProfiles();
    profilesList.innerHTML = html;
  }

  async refreshCurrentProfileAvatar(): Promise<void> {
    const profileAvatar = this.dropdownButton.querySelector(".profile_avatar");

    if (!profileAvatar) {
      return;
    }

    const profileId = await getCurrentProfileId();
    const selectedProfileId = getSelectedProfileId();
    const className = `header_small${selectedProfileId ? "" : " temporary"}`;
    const html = await getProfileAvatar(profileId, { className });
    profileAvatar.outerHTML = html;
  }

  onLinkClick({ delegateMatch: link }: Event): void {
    headerAccountBoxLinkClickHit(link.getAttribute(accountBoxLinkAttribute));
  }
}
