import Block from "./block";
import { headerLinkClickHit } from "../lib/tms/layout";
import { throttle } from "../lib/utils";
import { isSupportedBrowser, getUnsupportedBrowserDetails } from "../lib/device";

const scrollingClassName = "is-scrolling";
const linkNameAttribute = "data-header-link-name";

export default class HeaderBlock extends Block {
  hasScrollingClass: boolean;

  constructor(el: HTMLElement) {
    super(el);
    this.hasScrollingClass = false;
    this.init();
  }

  init(): void {
    this.onScroll();
    // event bindings
    this.dom.delegate(this.el, "click", `[${linkNameAttribute}]`, this.onClickLink.bind(this));
    this.dom.on(window, "scroll", throttle(this.onScroll.bind(this), 1000));

    if (!isSupportedBrowser()) {
      this.displayUnsupportedBrowserBanner();
    }
  }

  onScroll(): void {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    if (scrollTop > 0) {
      if (!this.hasScrollingClass) {
        this.el.classList.add(scrollingClassName);
        this.hasScrollingClass = true;
      }
    } else {
      if (this.hasScrollingClass) {
        this.el.classList.remove(scrollingClassName);
        this.hasScrollingClass = false;
      }
    }
  }

  onClickLink({ delegateMatch: link }: Event): void {
    headerLinkClickHit(link.getAttribute(linkNameAttribute));
  }

  async displayUnsupportedBrowserBanner(): Promise<void> {
    const fixedContainer = this.el.querySelector(".fixed");
    const { unsupportedBrowserBannerTemplate } = await import(
      "../templates/unsupported_browser_banner"
    );
    const banner = unsupportedBrowserBannerTemplate({ browser: getUnsupportedBrowserDetails() });

    fixedContainer.insertBefore(banner, fixedContainer.firstChild);

    // fix header's height
    if (!this.el.classList.contains("above")) {
      this.el.style.height = `${fixedContainer.clientHeight}px`;
    }
  }
}
