import * as dom from "../lib/dom";

export const runJsBlocks = (container: HTMLElement): void => {
  const jsBlocks = Array.from(container.querySelectorAll(".initjs"));
  // adds container as self block
  if (container.classList.contains("initjs")) {
    jsBlocks.unshift(container);
  }
  jsBlocks.forEach((element) => {
    const path = element.getAttribute("data-type") || "";
    const name = path.replace("blocks_", "");
    if (name) {
      import(`../blocks/${name}`).then(({ default: Module }) => {
        element.classList.remove("initjs");
        return new Module(element);
      });
    }
  });
};

export default class Block {
  el: HTMLElement;
  dom: typeof dom;

  constructor(el: HTMLElement) {
    // keep reference of the block element
    this.el = el;
    this.dom = dom;
  }
}
