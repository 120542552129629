import { apiHost, isAuthenticated as isAuthenticatedValue, thirdIdType } from "../page-data";
import { publish } from "../actions";
import { post } from "./fetch";
import { refresh as refreshToken } from "./token";
import { load as loadPromoCode, use as usePromoCode } from "./promo-code";
import { isSaved as isPairingCodeSaved, load as loadPairingCode } from "./pairing-code";
import { getPostLoginUrl } from "./user/subscription";
import { removeSelectedProfile } from "./user/profiles";
import { clearOffersCache } from "./offer";
import { getGigya } from "./gigya";

type AccountContext = null;

let accountPromise: Promise<Gigya.GetAccountInfoResponse<AccountContext>> = null;
const resetPasswordFlagName = "tfoumax_rpwd_flag";

const isNewUserKey = "tfoumax_new_user";
const isNewUserValue = "1";

export const getAuthAccount = (
  forceUpdate = false,
  params: Gigya.GetAccountInfoParameters<AccountContext> = null
): Promise<Gigya.GetAccountInfoResponse<AccountContext>> => {
  if (!forceUpdate && accountPromise) {
    return accountPromise;
  }

  accountPromise = new Promise((resolve) => {
    getGigya().then(({ accounts }) => {
      accounts.getAccountInfo<AccountContext>({
        ...params,
        ...{
          include: "identities-all,data,profile,preferences",
          callback(account) {
            return account.errorCode === 0 ? resolve(account) : resolve(null);
          }
        }
      });
    });
  });

  return accountPromise;
};

export const isNewUser = (): boolean =>
  window.sessionStorage.getItem(isNewUserKey) === isNewUserValue;

const setNewUser = (): void => window.sessionStorage.setItem(isNewUserKey, isNewUserValue);
const unsetNewUser = (): void => window.sessionStorage.removeItem(isNewUserKey);

export const isSubscribedNewsletter = async (): Promise<boolean> => {
  const account = await getAuthAccount();
  return account?.preferences?.optinActuTFOUMAX?.isConsentGranted === true;
};

export const isSubscribedTf1PlusPremium = async (): Promise<boolean> => {
  const account = await getAuthAccount();
  const endDate = account?.data?.myTf1?.subscription?.endDate;

  return !!endDate && new Date(endDate).getTime() > Date.now();
};

export const isGigyaAuthenticated = async (): Promise<boolean> => {
  const account = await getAuthAccount();
  return account?.isRegistered;
};

export const register = (): void => {
  window.location.href = "/pre-inscription";
};

export const logout = async (): Promise<void> => {
  const gigyaAuthenticated = await isGigyaAuthenticated();
  // no need to logout gigya
  if (!gigyaAuthenticated) {
    return onLogout();
  }

  return new Promise((resolve, reject) => {
    getGigya().then(({ accounts }) => {
      accounts.logout({
        forceProvidersLogout: false, // if social login, don't disconnect the user from facebook
        callback({ errorCode, errorMessage }) {
          if (errorCode === 0) {
            return resolve();
          } else {
            reject(new Error(`${errorMessage} [code ${errorCode}]`));
          }
        }
      });
    });
  });
};

const onLogout = async (): Promise<void> => {
  unsetNewUser();
  removeSelectedProfile();
  clearOffersCache();
  await Promise.all(publish("logout:success"));
  window.location.href = "/logout";
};

const setAccountInfo = (info: Gigya.SetAccountInfoParameters<AccountContext>): Promise<void> =>
  new Promise((resolve, reject) => {
    getGigya().then(({ accounts }) => {
      accounts.setAccountInfo({
        ...info,
        callback: (event) => {
          const errorCode = event.errorCode;

          if (errorCode) {
            const message = `${event.errorMessage} [code ${errorCode}]`;
            return reject(new Error(message));
          }
          // success
          getAuthAccount(true).then(() => resolve());
        }
      });
    });
  });

export const setAccountData = (data: Gigya.UserData): Promise<void> => setAccountInfo({ data });

const setAccountPreferences = (preferences: Gigya.UserPreferences): Promise<void> =>
  setAccountInfo({ preferences });

export const subscribeNewsletter = (): Promise<void> =>
  setAccountPreferences({ optinActuTFOUMAX: { isConsentGranted: true } });

export const unsubscribeNewsletter = (): Promise<void> =>
  setAccountPreferences({ optinActuTFOUMAX: { isConsentGranted: false } });

// use Gigya server REST API (can unsubscribe user with gigyaUid)
export const unsubscribeNewsletterServer = (gigyaUid: string): Promise<void> => {
  const url = `${apiHost}/account/newsletter/unsubscribe`;
  return post(url, { data: { uid: gigyaUid } });
};

// This boolean can be used to trigger actions just after the user logged in
const justAuthenticatedKey = "tfoumax_on_login";
const justAuthenticatedValue = "1";
const justAuthenticated =
  window.sessionStorage.getItem(justAuthenticatedKey) === justAuthenticatedValue;

window.sessionStorage.removeItem(justAuthenticatedKey);

export const isJustAuthenticated = (): boolean => justAuthenticated;

const setJustAuthenticated = (): void =>
  window.sessionStorage.setItem(justAuthenticatedKey, justAuthenticatedValue);

const resetToken = async (): Promise<void> => {
  await refreshToken(await getAuthAccount(), false, true);
  return window.location.reload();
};

// init gigya event handlers
getGigya().then(({ accounts }) => {
  accounts.addEventHandlers({
    onLogin(event) {
      const newUser = event.newUser;
      const keepMeLoggedIn = event.remember;
      setJustAuthenticated();
      newUser ? setNewUser() : unsetNewUser();

      removeSelectedProfile();
      clearOffersCache();

      Promise.all([
        refreshToken(event, newUser, keepMeLoggedIn), // set/refresh tfoumax token
        getAuthAccount(true) // update user storage
      ])
        .then(() => {
          // user has reset password
          // => set flag to prevent unique password screen to be displayed (after ssoToken setting)
          const resetPasswordFlag = window.localStorage.getItem(resetPasswordFlagName);
          if (resetPasswordFlag) {
            setAccountData({ tfoumax: { uniquePasswordGenerated: true } }).then(() => {
              window.localStorage.removeItem(resetPasswordFlagName);
            });
          }

          // trigger login success callbacks
          return Promise.all(publish("login:success"));
        })
        .then(() => {
          // Define next url to follow
          // promo code
          const code = loadPromoCode();
          if (code) {
            return usePromoCode(code).catch((error) => {
              console.error(error);
              // go to subscription management page on error
              return "/espace-parents/gerer-mon-abonnement";
            });
          }

          // no redirection if pairing is ongoing
          if (isPairingCodeSaved()) {
            return loadPairingCode().loginSuccessUrl;
          }

          return getPostLoginUrl("/", newUser);
        })
        .then((nextUrl) => {
          if (nextUrl === "none") {
            return;
          }

          const redirectURL = new URL(nextUrl, window.location.origin).toString();
          window.gigya.accounts.setSSOToken({ redirectURL });
        });
    },
    onLogout
  });
});

// check cookies consistency
// A. [tfoumax ❌ | gigya ❌] : OK
// B. [tfoumax ✅ | gigya ✅]
//   1. type auth == "gigya" : OK
//   2. type auth != "gigya" : reset tfoumax cookie with gigya type
// C. [tfoumax ✅ | gigya ❌]
//   1. type auth == "gigya" : logout + redirect to login page
//   2. type auth != "gigya" : OK
// D. [tfoumax ❌ | gigya ✅] : (e.g. coming from another SSO site) set tfoumax cookie with gigya type
export const initAuth = async (): Promise<void> => {
  const isGigyaAuthenticatedValue = await isGigyaAuthenticated();

  // A.
  if (!isAuthenticatedValue && !isGigyaAuthenticatedValue) {
    return;
  }

  // B.
  if (isAuthenticatedValue && isGigyaAuthenticatedValue) {
    if (thirdIdType === "gigya") {
      return;
    }

    return resetToken();
  }

  // C.
  if (isAuthenticatedValue && !isGigyaAuthenticatedValue) {
    if (thirdIdType !== "gigya") {
      return;
    }

    return logout();
  }

  // D.
  return resetToken();
};
