import { apiHost } from "../page-data";
import { parseJSON, get } from "./fetch";

let gigyaPromise: Promise<Gigya.WebSDK> = null;

export const getGigya = (): Promise<Gigya.WebSDK> => {
  // loaded
  if (gigyaPromise) {
    return gigyaPromise;
  }

  if (window.gigya) {
    gigyaPromise = Promise.resolve(window.gigya);
    return gigyaPromise;
  }

  // wait for gigya async callback to be called
  gigyaPromise = new Promise((resolve) => {
    window.onGigyaServiceReady = () => {
      resolve(window.gigya);
    };
  });

  return gigyaPromise;
};

export const isEmailOrPasswordError = (errorCode: number): boolean => Number(errorCode) === 403042;
export const isAccountPendingRegistrationError = (errorCode: number): boolean =>
  Number(errorCode) === 206001;

interface GigyaPasswordCheck {
  generateGlobalPassword: boolean;
}

const cache: Map<string, Promise<boolean>> = new Map();

export const existsWithoutUniquePassword = (email: string): Promise<boolean> => {
  if (cache.has(email)) {
    return cache.get(email);
  }

  const url = `${apiHost}/auth/gigya/password-check`;
  const promise = get<GigyaPasswordCheck>(url, { data: { email }, parse: parseJSON }).then(
    (data: GigyaPasswordCheck) => {
      return data?.generateGlobalPassword || false;
    },
    (error) => {
      console.error(error);
      return false;
    }
  );

  // save value
  cache.set(email, promise);
  return promise;
};
