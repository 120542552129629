export const parseJSON = <T>(string: string): JSON & T => {
  let json;
  try {
    json = JSON.parse(string);
  } catch (e) {
    json = null;
  }
  return json;
};

const encode = (data: string | Record<string, string | number | boolean> = null): string => {
  const e = window.encodeURIComponent;
  if (data === null) {
    return null;
  }
  if (typeof data === "string") {
    return data;
  }
  return Object.entries(data)
    .map(([key, value]) => `${e(key)}=${e(String(value))}`)
    .join("&");
};

export class AjaxError extends Error {
  status: number;
  xhr: XMLHttpRequest;
}

const createAjaxError = (xhr: XMLHttpRequest, message = null, status = null): AjaxError => {
  const error = new AjaxError(message || xhr.statusText);
  error.status = status || xhr.status;
  error.xhr = xhr;
  return error;
};

const getXhrPromise = (xhr: XMLHttpRequest): Promise<XMLHttpRequest["response"]> =>
  new Promise((resolve, reject) => {
    xhr.onload = () => {
      const isInvalidStatus =
        !xhr.status || ((xhr.status < 200 || xhr.status >= 300) && xhr.status !== 304);

      if (isInvalidStatus) {
        return reject(createAjaxError(xhr));
      }

      resolve(xhr.response);
    };

    xhr.onerror = () => reject(createAjaxError(xhr, "Network Error"));
  });

export interface AjaxOptions<T> {
  requestId?: string;
  parse?(xhr: XMLHttpRequest["response"]): T;
  headers?: Record<string, string>;
  data?: string | Record<string, string | number | boolean>;
  withCredentials?: boolean;
  responseType?: XMLHttpRequestResponseType;
}

const pendings: Map<string, Promise<unknown>> = new Map();
const defaultParse = <T>(x: T) => x; // identity

export const fetch = async <T>(
  method = "GET",
  url: string,
  options: AjaxOptions<T> = {}
): Promise<T> => {
  // group simultaneous request with same id
  const { requestId, ...fetchOptions } = options;
  const { parse = defaultParse, headers = {}, data, withCredentials, responseType } = fetchOptions;

  if (requestId) {
    let pending = <Promise<T>>pendings.get(requestId);

    if (!pending) {
      pending = fetch<T>(method, url, fetchOptions);
      pending.finally(() => pendings.set(requestId, null));
      pendings.set(requestId, pending);
    }

    return pending;
  }

  // default content type
  if (method === "POST" || method === "PUT") {
    headers["Content-Type"] ||= "application/x-www-form-urlencoded";
  }

  let payload = encode(data);

  // move payload as query params
  if (method === "GET" && payload) {
    url += (!url.includes("?") ? "?" : "&") + payload;
    payload = null;
  }

  const xhr = new XMLHttpRequest();

  xhr.open(method, url);

  for (const [header, value] of Object.entries(headers)) {
    xhr.setRequestHeader(header, String(value));
  }

  if (withCredentials) {
    xhr.withCredentials = true;
  }

  if (responseType) {
    xhr.responseType = responseType;
  }

  const xhrPromise = getXhrPromise(xhr);

  xhr.send(payload);

  const response = await xhrPromise;

  return parse(response);
};

export const get = <T>(url: string, options: AjaxOptions<T>): Promise<T> =>
  fetch<T>("GET", url, options);
export const post = <T>(url: string, options: AjaxOptions<T>): Promise<T> =>
  fetch<T>("POST", url, options);
export const put = <T>(url: string, options: AjaxOptions<T>): Promise<T> =>
  fetch<T>("PUT", url, options);
export const del = <T>(url: string, options: AjaxOptions<T>): Promise<T> =>
  fetch<T>("DELETE", url, options);
