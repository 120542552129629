import { apiHost, screen } from "../page-data";
import { downloadFile } from "./utils";
import { parseJSON, get, AjaxOptions } from "./fetch";
import { getAges } from "./user/preferences";
import { getCsa } from "./trusted-mode";

const cache: Map<string, unknown> = new Map();

const apiFetch = async <T>(
  path: string,
  data: AjaxOptions<T>["data"],
  withCredentials = false
): Promise<T> => {
  const requestId = `${path}:${JSON.stringify(data || {})}`;
  // already in cache
  if (cache.has(requestId)) {
    return <T>cache.get(requestId);
  }

  const response = await get<T>(apiHost + path, {
    requestId,
    parse: parseJSON,
    data,
    withCredentials
  });

  cache.set(requestId, response); // cache response
  return response;
};

export const getProgram = async (id: string): Promise<Api.Program> => {
  if (!id) {
    throw new Error("Program id is not defined");
  }

  const ages = await getAges();
  const data = await apiFetch<Api.Program>("/catalog/home/program", {
    agregat_id: id,
    ages: JSON.stringify(ages),
    screen,
    csa: getCsa()
  });

  return data;
};

export const getVideo = async (id: string): Promise<Api.Video> => {
  if (!id) {
    throw new Error("Video id is not defined");
  }

  const data = await apiFetch<Api.Video>("/catalog/home/video", {
    unique_id: id,
    screen,
    csa: getCsa()
  });

  return data;
};

export const downloadUserData = async (): Promise<void> => {
  const response = await get<Blob>(`${apiHost}/account/data/archive`, {
    withCredentials: true,
    responseType: "blob"
  });

  downloadFile(response, "donnees_tfoumax.zip", "application/octet-stream");
};
