import Splide from "@splidejs/splide";
import Block from "./block";
import { isActive } from "../page-data";
import { subscribe } from "../lib/user/subscription";
import { showcaseLinkClickHit, showcaseProgramLinkClickHit } from "../lib/tms/catalog";
import { addImageGab } from "../templates/helpers";

const tmsClickNameAttribute = "data-tms-click-name";

const splideI18n = {
  prev: "Diapositive précédente",
  next: "Diapositive suivante",
  first: "Aller à la première diapositive",
  last: "Aller à la dernière diapositive",
  slideX: "Aller à la diapositive %s",
  pageX: "Aller à la page %s",
  play: "Démarrer le défilement automatique",
  pause: "Mettre en pause le défilement automatique",
  select: "Sélectionner une diapositive à afficher",
  slideLabel: "%s sur %s"
};

export default class ShowcaseSliderBlock extends Block {
  backgroundSet: Set<number>;
  splide: Splide;

  constructor(el: HTMLElement) {
    super(el);
    this.backgroundSet = new Set();
    this.init();
  }

  async init(): Promise<void> {
    this.dom.delegate(this.el, "click", ".subscribe_button", ({ delegateMatch: button }: Event) =>
      this.onClickSubscribe(button)
    );

    this.dom.delegate(this.el, "click", ".program_link", (event: Event) => {
      const { delegateMatch: link } = event;
      event.preventDefault();
      this.onClickProgramLink(<HTMLAnchorElement>link);
    });

    this.dom.delegate(this.el, "click", `[${tmsClickNameAttribute}]`, ({ delegateMatch }: Event) =>
      showcaseLinkClickHit(delegateMatch.getAttribute(tmsClickNameAttribute))
    );

    const splide = new Splide(this.el, { i18n: splideI18n });

    this.splide = splide;
    splide.on("ready", this.onSliderReady.bind(this));
    splide.on("move", this.onSliderMove.bind(this));
    splide.mount();
  }

  onClickSubscribe(button: Element): void {
    const offerId = button.getAttribute("data-offer-id");
    subscribe({ offerId });
  }

  async onClickProgramLink(link: HTMLAnchorElement): Promise<void> {
    // follow program link if user has no active subscription
    if (!isActive) {
      window.location.href = link.href;
      return;
    }

    showcaseProgramLinkClickHit(link.getAttribute("data-program-name"));

    const { resumeProgram } = await import("../lib/video");

    resumeProgram(link.getAttribute("data-program-id"));
  }

  getGabId(responsiveGabIds: string): number {
    const tuples = responsiveGabIds.split(",").map((tuple) => tuple.split(":").map(Number));
    const refWidth = this.el.offsetWidth;
    const initialGabId = tuples[0][1];
    return tuples.reduce(
      (currentGabId, [minWidth, gabId]) => (refWidth >= minWidth ? gabId : currentGabId),
      initialGabId
    );
  }

  setSlideBackground(slideIndex: number): void {
    // do it only once
    if (this.backgroundSet.has(slideIndex)) {
      return;
    }

    const slide = this.splide.Components.Slides.getAt(slideIndex).slide;
    const slideBackgroundElement = <HTMLElement>slide.firstChild;
    const responsiveGabIds = slide.dataset.responsiveGabIds;
    const backgroundImageUrl = slide.dataset.backgroundUrl;
    const gabId = this.getGabId(responsiveGabIds);
    const backgroundImage = `url("${addImageGab(backgroundImageUrl, gabId)}")`;
    slideBackgroundElement.style.backgroundImage = backgroundImage;
    this.backgroundSet.add(slideIndex);
  }

  onSliderReady(): void {
    this.setSlideBackground(this.splide.index);
  }

  onSliderMove(newIndex: number): void {
    this.setSlideBackground(newIndex);
  }
}
