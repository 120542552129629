import { addQueryParamToUrl } from "./utils";
import { getOrigin } from "./origin";

export const getPaymentUrl = (offerId: string): string => {
  let url = "/payment";
  const origin = getOrigin();

  if (origin) {
    url = addQueryParamToUrl(url, "origin", origin);
  }

  if (offerId) {
    url = addQueryParamToUrl(url, "offerId", offerId);
  }

  return url;
};

export const getPaymentUpdateUrl = (): string => "/payment/update";
