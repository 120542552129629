import { apiHost, frontHost, screen } from "../../page-data";
import { promiseSeries } from "../utils";
import { delegate } from "../dom";
import { authUserFetch, isAuthenticated } from "./index";
import { resetTimer } from "./timer";

interface CustomUserPreferences extends User.Preferences {
  parentalAgesFilter: Api.Age[]; // coerce ages to an array
  parentalCode: string; // not optionnal, defaulted to empty string
}

const preferencesValues: Map<User.ProfileId, CustomUserPreferences> = new Map();

export const unlimitedTimeLimitValue = 0;

const parsePreferences = (preferences: User.Preferences): CustomUserPreferences => {
  const ages = preferences.parentalAgesFilter;
  // 'ALL' ou ['3-5', '6-8', '9-12'] => []
  const parsedAges = ages === "ALL" || (Array.isArray(ages) && ages.length === 3) ? [] : ages;
  // no parental code is same as empty string
  const parentalCode = preferences.parentalCode || "";

  return {
    ...preferences,
    parentalAgesFilter: parsedAges,
    parentalCode
  };
};

const parsePreferencesBeforeSet = (
  preferences: Partial<CustomUserPreferences>
): Partial<User.Preferences> => {
  const parsedPreferences: Partial<User.Preferences> = { ...preferences };
  const { parentalAgesFilter: ages } = preferences;
  // [] ou ['3-5', '6-8', '9-12'] => 'ALL'
  if (Array.isArray(ages) && (!ages.length || ages.length === 3)) {
    parsedPreferences.parentalAgesFilter = "ALL";
  }

  return parsedPreferences;
};

// return all preferences of a profile
export const getPreferences = async (
  profileId: User.ProfileId = null
): Promise<CustomUserPreferences> => {
  const cachedValue = preferencesValues.get(profileId);
  // fetch preferences only once
  if (typeof cachedValue === "object" && cachedValue !== null) {
    return cachedValue;
  }

  const url = `${apiHost}/userData/preferences${profileId ? `?profileId=${profileId}` : ""}`;
  const response = await authUserFetch<User.Preferences>(
    "GET",
    url,
    { screen },
    { withCredentials: true }
  );

  const value = parsePreferences(response);
  preferencesValues.set(profileId, value);
  return value;
};

const getPreferencesByKey = async <K extends keyof CustomUserPreferences>(
  key: K,
  profileId: User.ProfileId = null
): Promise<CustomUserPreferences[K]> => {
  const preferences = await getPreferences(profileId);
  return preferences[key];
};

const setPreferences = async (
  newPreferences: Partial<CustomUserPreferences>,
  profileId: User.ProfileId = null
): Promise<void> => {
  if (!(typeof newPreferences === "object" && newPreferences !== null)) {
    throw new Error("Preferences is not an object");
  }

  const parsedNewPreferences = parsePreferencesBeforeSet(newPreferences);

  // user must be a parent to update preferences
  await isParentStrict();

  // update properties one after the other
  await promiseSeries(
    Object.keys(parsedNewPreferences).map(
      (
          key // define task to be called one after the other
        ) =>
        async () => {
          const url = `${apiHost}/userData/preferences/update${
            profileId ? `?profileId=${profileId}` : ""
          }`;
          const value = parsedNewPreferences[key];
          const data = {
            key,
            value: typeof value === "string" ? value : JSON.stringify(value),
            screen
          };

          const response = await authUserFetch<User.Preferences>("GET", url, data, {
            withCredentials: true
          });

          preferencesValues.set(profileId, parsePreferences(response));
        }
    )
  );
};

export const getAges = async (
  profileId: User.ProfileId = null
): Promise<CustomUserPreferences["parentalAgesFilter"]> => {
  const authenticated = await isAuthenticated();

  // default anonymous value
  if (!authenticated) {
    return [];
  }

  const ages = await getPreferencesByKey("parentalAgesFilter", profileId);
  return Array.from(ages);
};

export const setAges = async (value: Api.Age[], profileId: User.ProfileId = null): Promise<void> =>
  setPreferences({ parentalAgesFilter: value }, profileId);

export const getTimeLimit = async (
  profileId: User.ProfileId = null
): Promise<CustomUserPreferences["totalTimeLimit"]> => {
  const timeLimit = await getPreferencesByKey("totalTimeLimit", profileId);
  return timeLimit || unlimitedTimeLimitValue;
};

export const setTimeLimit = async (
  value: number,
  profileId: User.ProfileId = null
): Promise<CustomUserPreferences["totalTimeLimit"]> => {
  await setPreferences({ totalTimeLimit: value }, profileId);
  const timeLimit = await getTimeLimit(profileId);
  resetTimer(profileId);
  return timeLimit;
};

export const hasParentalCode = async (): Promise<boolean> => {
  const code = await getPreferencesByKey("parentalCode");
  return code !== "";
};

const isParentalCode = async (code: string): Promise<boolean> => {
  const has = await hasParentalCode();

  // no parental code = anything is ok
  if (!has) {
    return true;
  }

  // checking
  const parentalCode = await getPreferencesByKey("parentalCode");
  return code === parentalCode;
};

const isParentFlagKey = "isparent";
const isParentFlagValue = "1";
const isParentFlagStorage = window.sessionStorage;
const loadIsParentFlag = () => isParentFlagStorage.getItem(isParentFlagKey) === isParentFlagValue;
const hasParentFlag = loadIsParentFlag;
const setIsParentFlag = () => isParentFlagStorage.setItem(isParentFlagKey, isParentFlagValue);
const removeIsParentFlag = () => isParentFlagStorage.removeItem(isParentFlagKey);

// prompt for code until answer is correct
export const isParent: () => Promise<boolean> = (() => {
  let isParentFlag = false;
  let modal = null;
  let reply = null;

  const openModal = async () => {
    // open previously created modal
    if (modal !== null) {
      modal.open();
      return;
    }

    const { default: ParentalCodeModal } = await import("../../modals/parentalCodeModal");

    // create modal
    modal = new ParentalCodeModal();

    // subscribe to user actions (submit/cancel)
    modal.actions.subscribe("submit:parental-code", async (code) => {
      try {
        const is = await isParentalCode(code);
        // try again (or give up)
        if (!is) {
          modal.setErrorMessage("Le code parental saisi est incorrect.");
          return;
        }
        // successfully guessed
        isParentFlag = true;
        setIsParentFlag();
        modal.close();
        reply(true);
      } catch (error) {
        console.error(error);
        // rerender with error display
        modal.setErrorMessage("Une erreur s'est produite.");
      }
    });

    modal.actions.subscribe("cancel:parental-code", () => {
      // user is giving up
      modal.close();
      reply(false);
    });

    modal.open();
  };

  return async (): Promise<boolean> => {
    isParentFlag = loadIsParentFlag();

    // already guessed
    if (isParentFlag) {
      return true;
    }

    const has = await hasParentalCode();

    // don't bother if no parental code is set
    if (!has) {
      isParentFlag = true;
      setIsParentFlag();
      return true;
    }

    // return a pending promise waiting for the user success/fail
    return new Promise((resolve) => {
      // the subscriptions to the modal actions are made once,
      // we have to keep up to date the reply reference in a higher context
      // (else it would always be the resolve function at creation time)
      reply = resolve;
      openModal();
    });
  };
})();

export const isParentStrict = async (): Promise<true | never> => {
  const is = await isParent();

  // throw if not the parent
  if (!is) {
    throw new Error("Not authorized. Parental code has not been guessed.");
  }

  return is;
};

export const setParentalCode = async (newCode: string): Promise<void> => {
  if (typeof newCode !== "string") {
    throw new Error("New code is not defined");
  }
  return setPreferences({ parentalCode: newCode });
};

export const deleteParentalCode = (): Promise<void> => setParentalCode("");

export const emailParentalCode = (): Promise<void> => {
  const url = `${apiHost}/userData/parental-code/mail`;
  return authUserFetch("GET", url, null, { withCredentials: true });
};

const isParentalSpaceUrl = (url: string): boolean =>
  new URL(url, window.location.href).href.startsWith(`${frontHost}/espace-parents`);

const setPageContentVisibility = (value: string): void => {
  (<HTMLElement>document.querySelector("#global_wrapper")).style.visibility = value;
};
const hidePageContent = (): void => setPageContentVisibility("hidden");
const showPageContent = (): void => setPageContentVisibility("visible");

export const checkParentBeforeNavigate = async (
  successUrl: string = null,
  cancelUrl: string = null,
  { blockPageContent = false } = {}
): Promise<void> => {
  if (blockPageContent) {
    hidePageContent();
  }

  const nextUrl = (await isParent()) ? successUrl : cancelUrl;

  if (nextUrl === successUrl) {
    showPageContent();
  }

  if (nextUrl) {
    window.location.href = nextUrl;
  }
};

export const initParentalSpaceGuard = (): void => {
  const isParentalSpacePage = isParentalSpaceUrl(window.location.href);

  if (isParentalSpacePage && !hasParentFlag()) {
    checkParentBeforeNavigate(null, "/", { blockPageContent: true });
  }

  if (!isParentalSpacePage && hasParentFlag()) {
    removeIsParentFlag();
  }

  delegate(document, "click", "a", (event: Event) => {
    const { href } = <HTMLAnchorElement>event.delegateMatch;

    if (!isParentalSpaceUrl(href)) {
      return;
    }

    event.preventDefault();
    checkParentBeforeNavigate(href);
  });
};
