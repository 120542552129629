import { createElement } from "../lib/dom";

interface SubscriptionCtaData {
  trial: boolean;
  testId?: string;
}

export const subscriptionCtaTemplate = ({ trial, testId = "" }: SubscriptionCtaData): HTMLElement =>
  createElement(
    "button",
    "unstyled_button subscription_cta_button",
    { type: "button", dataset: { testid: testId } },
    [trial ? "Essayez gratuitement" : "Réactiver mon abonnement"]
  );
